import React from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import CampaignTypeSelector from './CampaignTypeSelector';

const CampaignSelectorModal = ({ open, onClose, onSelect, disabled }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          padding: '16px',
          minWidth: '600px',
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#015d86',
        fontSize: '18px',
        fontWeight: 600,
        pb: 1,
      }}>
        Select Campaign Type
        <IconButton 
          onClick={onClose}
          size="small"
          sx={{ 
            color: '#666',
            '&:hover': { 
              backgroundColor: 'rgba(0, 0, 0, 0.04)' 
            }
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <CampaignTypeSelector 
        onSelect={(type) => {
          onSelect(type);
          onClose();
        }}
        disabled={disabled} 
      />
    </Dialog>
  );
};

export default CampaignSelectorModal; 