import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  StyledPaper,
  StyledTableCell,
  StyledTableRow,
  AvatarContainer,
  NameContainer,
  tableStyles,
} from "./DataTable.styles";
import PropTypes from "prop-types";
import {
  faCircleCheck,
  faEnvelope,
  faPenToSquare,
  faTrash,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHand } from "@fortawesome/free-regular-svg-icons";

const DataTable = ({
  type,
  data,
  columns,
  onSelect,
  selectedRows = [],
  selectable = false,
  showCheckboxes = false,
  multiSelect = false,
  onEdit,
  onDelete,
  onRowClick,
  onCancel,
}) => {
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    // Sort by insert_date if it exists (for campaigns), otherwise use default sorting
    if (type === "campaigns") {
      const sorted = [...data].sort((a, b) => {
        const dateA = new Date(a.insert_date);
        const dateB = new Date(b.insert_date);
        return dateB - dateA; // Sort in descending order (newest to oldest)
      });
      setSortedData(sorted);
    } else {
      // Keep existing default sorting for other table types
      const firstColumn = columns.find((col) => col.id !== "checkbox");
      if (firstColumn) {
        const sorted = [...data].sort((a, b) => {
          const valA = (a[firstColumn.id] || "").toString().toLowerCase();
          const valB = (b[firstColumn.id] || "").toString().toLowerCase();

          if (valA === "" && valB !== "") return 1;
          if (valB === "" && valA !== "") return -1;
          return valA.localeCompare(valB);
        });
        setSortedData(sorted);
      }
    }
  }, [data, columns, type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    return selectedRows.some((selectedRow) => selectedRow.id === row.id);
  };

  const handleSelectAllClick = (event) => {
    if (!onSelect) return;

    if (event.target.checked) {
      onSelect(data);
    } else {
      onSelect([]);
    }
  };

  const handleRowClick = (event, row) => {
    if (!selectable || !onSelect) {
      if (onRowClick) {
        onRowClick(row);
      }
      return;
    }

    // Prevent row selection when clicking checkbox
    if (event.target.type === "checkbox") {
      event.stopPropagation();
      return;
    }

    onSelect(row);
  };

  const handleCheckboxClick = (event, row) => {
    event.stopPropagation();
    if (!onSelect) return;
    onSelect(row);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEdit = () => {
    if (selectedRow) {
      onEdit(selectedRow);
      handleMenuClose();
    }
  };

  const handleDelete = () => {
    if (selectedRow) {
      onDelete(selectedRow.id);
      handleMenuClose();
    }
  };

  const handleCancel = () => {
    if (selectedRow) {
      onCancel(selectedRow.id);
      handleMenuClose();
    }
  };

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <StyledPaper>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              {showCheckboxes && (
                <StyledTableCell sx={tableStyles.checkbox}>
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data.length
                    }
                    checked={
                      data.length > 0 && selectedRows.length === data.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCell>
              )}
              {columns.map((column) => (
                <StyledTableCell key={column.id}>
                  {column.label}
                </StyledTableCell>
              ))}
              {(type === "customer" || type === "campaigns") && (
                <StyledTableCell>Actions</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => {
              const isItemSelected = isSelected(row);
              return (
                <StyledTableRow
                  hover
                  key={row.id}
                  selected={isItemSelected}
                  selectable={selectable.toString()}
                >
                  {showCheckboxes && (
                    <StyledTableCell sx={tableStyles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(e) => handleCheckboxClick(e, row)}
                      />
                    </StyledTableCell>
                  )}
                  {columns.map((column) => (
                    <StyledTableCell key={column.id}>
                      {column.id === "name" ? (
                        <AvatarContainer>
                          <Avatar>{row?.name[0]}</Avatar>
                          <NameContainer>
                            <Typography variant="body1">{row.name}</Typography>
                          </NameContainer>
                        </AvatarContainer>
                      ) : column.id.startsWith("metadata[") ? (
                        row.metadata[column.id.slice(9, -1)]
                      ) : column.id === "status" &&
                        row[column.id] === "PUBLISHED" ? (
                        <>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{
                              color: "green",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Typography variant="button" sx={{ color: "green" }}>
                            {row.status}
                          </Typography>
                        </>
                      ) : column.id === "status" &&
                        row[column.id] === "draft" ? (
                        <>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{
                              color: "grey",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Typography variant="button" sx={{ color: "grey" }}>
                            {row.status}
                          </Typography>
                        </>
                      ) : column.id === "status" &&
                        row[column.id].toLowerCase() === "manual" ? (
                        <>
                          <FontAwesomeIcon
                            icon={faHand}
                            style={{
                              color: "#800000",
                              marginRight: "5px",
                            }}
                          />{" "}
                          <Typography
                            variant="button"
                            sx={{ color: "#800000" }}
                          >
                            {row.status}
                          </Typography>
                        </>
                      ) : (
                        row[column.id]
                      )}
                    </StyledTableCell>
                  ))}
                  {type === "customer" && (
                    <StyledTableCell>
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e, row);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleEdit}>Edit</MenuItem>
                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                      </Menu>
                    </StyledTableCell>
                  )}
                  {type === "campaigns" && (
                    <StyledTableCell>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        {row.status !== "draft" && (
                          <Tooltip title="See conversations" placement="top">
                            <Button
                              variant="contained"
                              onClick={(event) => handleRowClick(event, row)}
                              sx={{
                                width: "auto",
                                paddingLeft: "-10px",
                                backgroundColor: "#020A30",
                                "&:hover": { backgroundColor: "#3c3c3c" },
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "3px",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        )}
                        <Tooltip title="Edit campaign" placement="top">
                          <Button
                            variant="contained"
                            onClick={() => onEdit(row)}
                            sx={{
                              width: "auto",
                              paddingLeft: "-10px",
                              backgroundColor: "#0D92B7",
                              "&:hover": { backgroundColor: "#239ED0" },
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              style={{
                                fontSize: "20px",
                                marginBottom: "3px",
                              }}
                            />
                          </Button>
                        </Tooltip>
                        {/* <Button
                          variant="contained"
                          onClick={() => onDelete(row.id)}
                          sx={{
                            backgroundColor: "#d32f2f",
                            "&:hover": { backgroundColor: "#b71c1c" },
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ fontSize: "20px", marginBottom: "3px" }}
                          />
                        </Button> */}
                      </Box>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={tableStyles.paginationContainer}
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
};

DataTable.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  selectedRows: PropTypes.array,
  selectable: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRowClick: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DataTable;
