import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { PulseLoader } from "react-spinners";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { Check, ContentCopy, ThumbDown, ThumbUp } from "@mui/icons-material";
import FeedbackDialog from "./FeedbackDialog";
import AppIcon from "../../images/AppIcon.png";

const ChatWindow = ({ isDarkMode, messages, dataMessages }) => {
  const messagesEndRef = useRef(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [feedbackData, setFeedbackData] = useState({});
  const [thumbUpState, setThumbUpState] = useState({});
  const [thumbDownState, setThumbDownState] = useState({});
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const backgroundColorStyle = isDarkMode ? "#272727" : "#fff";
  // const fontColorStyle = mode === "dark" ? "white" : "black";

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [messages]);

  const handleHiddenDialog = () => {
    setOpenFeedbackDialog(false);
    setSnackbarMessage("Negative feedback sent successfully");
    setSnackbarOpen(true);
  };

  const handleFeedback = (index, isPositive) => {
    setThumbUpState((prevState) => ({
      ...prevState,
      [index]: isPositive ? !prevState[index] : false,
    }));
    setThumbDownState((prevState) => ({
      ...prevState,
      [index]: !isPositive ? !prevState[index] : false,
    }));

    if (!isPositive && !thumbDownState[index]) {
      const feedbackDataObject = dataMessages?.find(
        (message) => message.message === messages[index].message
      );
      setFeedbackData(feedbackDataObject);
      setOpenFeedbackDialog(true);
    } else {
      setSnackbarMessage("Positive feedback sent successfully");
      setSnackbarOpen(true);
    }
  };

  const handleCopyClick = (message, index) => {
    navigator.clipboard.writeText(message).then(() => {
      setCopiedIndex(index);
      setTimeout(() => {
        setCopiedIndex(null);
      }, 2000);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          height: "80%",
          padding: "16px",
          maxHeight: "78%",
          overflowY: "auto",
          minHeight: "50vh",
          backgroundColor: backgroundColorStyle,
        }}
      >
        <Scrollbars
          style={{ width: "100%", height: "100%", overflowX: "hidden" }}
        >
          {messages.map((item, index) => (
            <Box
              key={index}
              className={`message-container ${item.type}`}
              sx={{
                display: "flex",
                marginBottom: "12px",
                alignItems: "flex-start",
              }}
            >
              {item.type === "received" ? (
                <Avatar
                  src={AppIcon}
                  sx={{ marginTop: "18px", marginLeft: "10px" }}
                />
              ) : (
                <Avatar
                  src="/broken-image.jpg"
                  sx={{
                    order: "1",
                    marginTop: "6px",
                    marginRight: "12px",
                  }}
                />
              )}
              <Box
                className="message"
                sx={{
                  order: "0",
                  width: "100%",
                  padding: "12px",
                  borderRadius: "12px",
                  margin: "0px 20px 0px 15px",
                  color: item.type === "sent" ? "#ffffff" : "#000000",
                  backgroundColor: item.type === "sent" ? "#0D92B7" : "#F3F4F6",
                }}
              >
                <Box
                  sx={{
                    padding: "5px",
                    fontSize: "15px",
                    marginTop: "-17px",
                    marginBottom: "-17px",
                  }}
                >
                  {item.isLoading ? (
                    <PulseLoader
                      size={10}
                      color={"black"}
                      cssOverride={{ marginTop: "10px", marginBottom: "25px" }}
                    />
                  ) : (
                    <Markdown>{item.message}</Markdown>
                  )}
                </Box>
                {item.type === "received" && !item.isLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "8px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Copy" placement="bottom">
                      <IconButton
                        size="small"
                        sx={{ marginRight: "5px" }}
                        color={"default"}
                        onClick={() => handleCopyClick(item.message, index)}
                      >
                        {copiedIndex === index ? (
                          <Check fontSize="small" />
                        ) : (
                          <ContentCopy fontSize="small" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      size="small"
                      sx={{ marginRight: "5px" }}
                      color={thumbUpState[index] ? "success" : "default"}
                      onClick={() => handleFeedback(index, true)}
                    >
                      <ThumbUp fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      color={thumbDownState[index] ? "error" : "default"}
                      onClick={() => handleFeedback(index, false)}
                    >
                      <ThumbDown fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
          <Box ref={messagesEndRef} />
        </Scrollbars>
        <FeedbackDialog
          show={openFeedbackDialog}
          data={feedbackData}
          handleClose={handleHiddenDialog}
        />
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default ChatWindow;
