import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faChevronDown,
  faChevronUp,
  faMagnifyingGlass,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

const QuestionsList = ({
  questionGroups,
  onAdd,
  onEdit,
  onDeleteInterview,
  onDeleteQuestion,
}) => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [groupAnchorEls, setGroupAnchorEls] = useState({});
  const [questionAnchorEl, setQuestionAnchorEl] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleToggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleQuestionMenuOpen = (event, question) => {
    setQuestionAnchorEl(event.currentTarget);
    setSelectedQuestion(question);
  };

  const handleQuestionMenuClose = () => {
    setQuestionAnchorEl(null);
    setSelectedQuestion(null);
  };

  const handleGroupMenuOpen = (event, groupId) => {
    setGroupAnchorEls((prev) => ({ ...prev, [groupId]: event.currentTarget }));
  };

  const handleGroupMenuClose = (groupId) => {
    setGroupAnchorEls((prev) => {
      const newAnchorEls = { ...prev };
      delete newAnchorEls[groupId];
      return newAnchorEls;
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredInterviews = questionGroups?.filter((group) =>
    group?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: 2 }}>
      <TextField
        value={searchTerm}
        variant="outlined"
        placeholder="Search"
        onChange={handleSearchChange}
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          borderRadius: "25px",
          "& .MuiOutlinedInput-root": {
            width: isMobile ? "100%" : "300%",
            height: "36px",
            fontSize: "13px",
            borderRadius: "20px",
            "&:hover fieldset": { borderColor: "black" },
            "&.Mui-focused fieldset": { borderColor: "black" },
          },
        }}
        InputProps={{
          startAdornment: (
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{
                fontSize: "16px",
                marginLeft: "5px",
                marginRight: "7px",
              }}
            />
          ),
          sx: {
            fontSize: "13px",
            paddingLeft: "8px",
            borderRadius: "25px",
          },
        }}
      />
      <List>
        {filteredInterviews?.map((interview, index) => (
          <Box
            key={interview.id}
            sx={{
              borderRadius: "6px",
              marginBottom: "10px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
            }}
          >
            <ListItem>
              <ListItemText primary={interview.name} />
              <IconButton onClick={(e) => handleGroupMenuOpen(e, interview.id)}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={groupAnchorEls[interview.id]}
                open={Boolean(groupAnchorEls[interview.id])}
                onClose={() => handleGroupMenuClose(interview.id)}
              >
                <MenuItem
                  onClick={() => {
                    handleGroupMenuClose(interview.id);
                    onEdit(interview);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ marginRight: "12px" }}
                  />
                  <ListItemText primary="Edit questions" />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleGroupMenuClose(interview.id);
                    onDeleteInterview(interview.id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBan}
                    style={{ marginRight: "12px" }}
                  />
                  <ListItemText primary="Delete interview" />
                </MenuItem>
              </Menu>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleExpand(index);
                }}
                edge="end"
              >
                <FontAwesomeIcon
                  icon={expandedIndex === index ? faChevronUp : faChevronDown}
                  style={{ fontSize: "16px", marginLeft: "2px" }}
                />
              </IconButton>
            </ListItem>
            <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
              <Box sx={{ paddingLeft: 4 }}>
                {interview.questions.map((question, qIndex) => (
                  <Box
                    key={qIndex}
                    sx={{
                      marginRight: "30px",
                      borderRadius: "6px",
                      marginBottom: "10px",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                    }}
                  >
                    <ListItem sx={{ padding: "3px 0 3px 10px" }}>
                      <ListItemText
                        primary={question}
                        sx={{
                          fontSize: "12px",
                        }}
                      />
                      <IconButton
                        onClick={(e) => handleQuestionMenuOpen(e, question)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={questionAnchorEl}
                        open={
                          Boolean(questionAnchorEl) &&
                          selectedQuestion === question
                        }
                        onClose={handleQuestionMenuClose}
                      >
                        <MenuItem
                          onClick={() => {
                            handleQuestionMenuClose();
                            onEdit(interview);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{ marginRight: "12px" }}
                          />
                          <ListItemText primary="Edit" />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleQuestionMenuClose();
                            onDeleteQuestion(interview, qIndex);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBan}
                            style={{ marginRight: "12px" }}
                          />
                          <ListItemText primary="Delete" />
                        </MenuItem>
                      </Menu>
                    </ListItem>
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        ))}
      </List>
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          variant="contained"
          onClick={onAdd}
          sx={{
            marginTop: "10px",
            textTransform: "none",
            backgroundColor: "#0D92B7",
            "&:hover": { backgroundColor: "#015d86" },
          }}
        >
          <Typography sx={{ fontSize: "15px", marginTop: "1px" }}>
            Add interview
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default QuestionsList;
