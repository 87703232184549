import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { CloudUpload } from "@mui/icons-material";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFolderOpen,
  faHammer,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataFetcher from "../../utils/DataFetcher";
import { FETCH_RETRIES } from "../PromptDashboard/utils/constants";
import InputVariablesFields from "./components/InputVariablesFields";
import { Auth } from "aws-amplify";

const AssistantDetails = ({
  selectedPrompt,
  applicantId,
  agentDeleted,
  onUploadFile,
  onCreateOrUpdate,
  onDelete,
  onSelectAgent,
  toggleFileList,
  editMode,
  isFieldEditable,
  campaignData,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dataFetcher = DataFetcher();
  const [agents, setAgents] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [variables, setVariables] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [assistantName, setAssistantName] = useState("");
  const [selectedAgent, setSelectedAgent] = useState({});
  const [agentsLoaded, setAgentsLoaded] = useState(false);
  const [customerFromClaim, setCustomer] = useState(false);
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [displayFields, setDisplayFields] = useState(false);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [openAgentsList, setOpenAgentsList] = useState(false);
  const [openPromptsList, setOpenPromptsList] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(selectedPrompt ?? null);
  const [instructions, setInstructions] = useState(
    selectedPrompt ? selectedPrompt.human_instruction : ""
  );

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const clearFields = async () => {
      if (agentDeleted === "Yes") {
        await fetchAgents(applicantId);
        setAssistantName("");
        setInstructions("");
        setSelectedAgent({});
      }
      setLoadingAction(false);
    };

    clearFields();
  }, [applicantId, agentDeleted]);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);
      } catch (e) {
        console.log("No current user", e);
      }
    };

    checkCustomer();
  }, []);

  useEffect(() => {
    if (instructions) {
      parseVariables(instructions);
    } else {
      setVariables([]);
    }
  }, [instructions]);

  useEffect(() => {
    if (applicantId && customerFromClaim) {
      console.log("Initial agents fetch:", { applicantId, customerFromClaim });
      fetchAgents(applicantId);
    }
  }, [applicantId, customerFromClaim]);

  useEffect(() => {
    console.log("Campaign Data Effect:", {
      campaignData,
      editMode,
      agentsLoaded,
      agents: agents.length,
    });

    if (campaignData?.metadata?.ai_agent && editMode && agents.length > 0) {
      const agentId = campaignData.metadata.ai_agent;

      // Find the agent in the agents list
      const agent = agents.find((a) => a.id === agentId);

      if (agent) {
        console.log("Found agent:", agent);
        handleSelectAgent({
          id: agentId,
          name: agent.name,
          prompt: agent.prompt || campaignData.instructions || "",
        });

        // Force display fields to show agent info
        setDisplayFields(true);
      }
    }
  }, [campaignData, editMode, agents, agentsLoaded]);

  // Add effect to update agent name when agents list loads
  useEffect(() => {
    if (
      editMode &&
      selectedAgent?.id &&
      !selectedAgent.name &&
      agents.length > 0
    ) {
      const agent = agents.find((a) => a.id === selectedAgent.id);
      if (agent) {
        setSelectedAgent((prev) => ({
          ...prev,
          name: agent.name,
        }));
        setAssistantName(agent.name);
      }
    }
  }, [agents, editMode, selectedAgent?.id]);

  const fetchAgents = async (applicantId) => {
    console.log("Fetching agents:", { applicantId, customerFromClaim });
    setLoadingAgents(true);
    try {
      const fetchedAgents = await dataFetcher.fetchAgents(
        applicantId,
        customerFromClaim
      );
      console.log("Fetched agents:", fetchedAgents);
      setAgents(fetchedAgents);
      setLoadingAgents(false);
      setAgentsLoaded(true);

      // If in edit mode and we have campaign data, try to select agent immediately
      if (editMode && campaignData?.metadata?.ai_agent) {
        const agent = fetchedAgents.find(
          (a) => a.id === campaignData.metadata.ai_agent
        );
        if (agent) {
          handleSelectAgent({
            id: agent.id,
            name: agent.name,
            prompt: agent.prompt || campaignData.instructions || "",
          });
          setDisplayFields(true);
        }
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
      setLoadingAgents(false);
      throw new Error("Error fetching agents:", error);
    }
  };

  const fetchPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const fetchedPrompts = await dataFetcher.fetchPrompts(applicantId);
      setPrompts(fetchedPrompts);
      setLoadingPrompts(false);
      setPromptsLoaded(true);
    } catch (error) {
      console.log("Error fetching prompts:", error);
      setLoadingPrompts(false);
      setPrompts([]);
    }
  };

  const parseVariables = (str) => {
    if (!str || typeof str !== "string") {
      console.log("No valid string provided to parseVariables:", str);
      setVariables([]);
      return;
    }

    try {
      const matches = [...str.matchAll(/\{(.*?)\}/g)];
      const parsedVariables = matches.map((match) => ({
        name: match[1],
        value: "",
      }));

      setVariables(parsedVariables);
    } catch (error) {
      console.error("Error parsing variables:", error);
      setVariables([]);
    }
  };

  const replaceVariablesInString = (promptInstructions) => {
    let resultString = promptInstructions;

    variables.forEach(({ name, value }) => {
      const regex = new RegExp(`\\{${name}\\}`, "g");

      resultString = resultString.replace(regex, value);
    });

    return resultString;
  };

  const onDrop = (acceptedFiles) => {
    setErrorMessage("");
    onUploadFile(acceptedFiles);
  };

  const onDropRejected = (rejectedFiles) => {
    const unsupportedFile = rejectedFiles[0];
    if (unsupportedFile) {
      setErrorMessage(
        `File ${unsupportedFile.file.name} is not a supported format. Only DOCX or PDF files.`
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  const handleSelectAgent = (agent) => {
    console.log("AssistantDetails handleSelectAgent called with:", agent);

    if (!agent?.id) {
      console.log(
        "No valid agent provided to AssistantDetails handleSelectAgent"
      );
      return;
    }

    onSelectAgent(agent);
    setOpenAgentsList(false);
    setSelectedAgent(agent);

    if (!editMode || agent.id !== selectedAgent?.id) {
      setAssistantName(agent.name || "");
    }

    setInstructions(agent.prompt || "");

    console.log("AssistantDetails agent selection complete:", {
      selectedAgent: agent,
      assistantName: agent.name,
      instructions: agent.prompt,
      currentPrompt,
    });
  };

  const handleNewAgent = () => {
    setDisplayFields(true);
    setAssistantName("");
    setInstructions("");
    setSelectedAgent({});
    setCurrentPrompt(null);
  };

  const handleCreateOrUpdate = async () => {
    setLoadingAction(true);
    const data = {
      instructions:
        variables.length > 0
          ? replaceVariablesInString(instructions)
          : instructions,
      name: assistantName,
      selectedAgentId: selectedAgent.id ?? "",
    };
    await onCreateOrUpdate(data);
    await fetchAgents(applicantId);
    setLoadingAction(false);
  };

  const handleDelete = async () => {
    setLoadingAction(true);
    await onDelete();
  };

  const handleSelectPrompt = (prompt) => {
    setCurrentPrompt(prompt);
    setOpenPromptsList(false);
    setInstructions(prompt?.human_instruction);
  };

  const handleUpdateVariables = (index, updatingName, value) => {
    setVariables((prev) => {
      const updatedVariables = [...prev];
      const updatedVariable = { ...updatedVariables[index] };

      if (updatingName) {
        updatedVariable.name = value;
      } else {
        updatedVariable.value = value;
      }
      updatedVariables[index] = updatedVariable;

      return updatedVariables;
    });
  };

  console.log("AssistantDetails render state:", {
    selectedAgent,
    editMode,
    isFieldEditable,
    campaignData,
    assistantName,
    instructions,
    variables,
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "89vh",
        padding: "16px",
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
      }}
    >
      <FormControl fullWidth sx={{ marginTop: "6px" }}>
        <InputLabel sx={{ fontSize: "13px", margin: "-5px", fontWeight: 700 }}>
          Select Assistant
        </InputLabel>
        <Select
          displayEmpty
          open={openAgentsList}
          value={selectedAgent?.name || ""}
          onOpen={() => {
            if (!agentsLoaded) {
              fetchAgents(applicantId);
            }
            setOpenAgentsList(true);
          }}
          onClose={() => setOpenAgentsList(false)}
          renderValue={(selected) => {
            return selected || "";
          }}
          sx={{
            height: "40px",
            "& .MuiSelect-select": {
              fontSize: "13px",
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                minWidth: "100%",
                maxWidth: "100%",
                maxHeight: "200px",
              },
            },
          }}
        >
          {loadingAgents ? (
            <MenuItem disabled>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <CircularProgress size={24} />
              </Box>
            </MenuItem>
          ) : agents?.length > 0 ? (
            <Scrollbars style={{ height: "180px", width: "100%" }}>
              {agents.map((agent) => (
                <MenuItem
                  key={agent.id}
                  value={agent.name}
                  sx={{
                    fontSize: "12px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => handleSelectAgent(agent)}
                >
                  {agent.name}
                </MenuItem>
              ))}
            </Scrollbars>
          ) : (
            <MenuItem disabled>No assistants found</MenuItem>
          )}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleNewAgent}
        sx={{
          color: "white",
          marginTop: "10px",
          marginBottom: "10px",
          alignSelf: "center",
          textTransform: "none",
          backgroundColor: "#0D92B7",
          "&:hover": { backgroundColor: "#014a6d" },
          "& .MuiButton-startIcon>*:nth-of-type(1)": {
            fontSize: "14px !important",
          },
        }}
        startIcon={<Box>&#128640;</Box>}
      >
        <Typography sx={{ fontSize: "13px" }}>New Agent</Typography>
      </Button>
      {(selectedPrompt !== null ||
        displayFields ||
        Object.keys(selectedAgent).length > 0) && (
        <>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              fontSize: "14px",
              marginTop: "2px",
              marginBottom: "8px",
            }}
          >
            Name:
          </Typography>
          <TextField
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            fullWidth
            InputProps={{
              sx: {
                height: "40px",
                fontSize: "14px",
              },
            }}
            sx={{
              marginBottom: "8px",
            }}
            disabled={editMode && !isFieldEditable("agent")}
          />
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              fontSize: "14px",
              marginTop: "2px",
              marginBottom: "8px",
            }}
          >
            Prompt:
          </Typography>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                margin: "-5px",
                fontWeight: 700,
                fontSize: "13px",
              }}
            >
              Select Prompt
            </InputLabel>
            <Select
              displayEmpty
              open={openPromptsList}
              value={currentPrompt?.name || ""}
              onOpen={() => {
                if (!promptsLoaded) {
                  fetchPrompts();
                }
                setOpenPromptsList(true);
              }}
              onClose={() => setOpenPromptsList(false)}
              renderValue={(selected) => {
                return selected || "";
              }}
              sx={{
                height: "40px",
                marginBottom: "8px",
                "& .MuiSelect-select": {
                  fontSize: "13px",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    minWidth: "100%",
                    maxWidth: "100%",
                    overflow: "hidden",
                    maxHeight: "155px",
                  },
                },
              }}
            >
              {loadingPrompts ? (
                <MenuItem disabled>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CircularProgress size={24} />
                  </Box>
                </MenuItem>
              ) : prompts?.length > 0 ? (
                <Scrollbars style={{ height: "140px", width: "100%" }}>
                  {prompts.map((prompt) => (
                    <MenuItem
                      key={prompt.name}
                      value={prompt.name}
                      sx={{
                        fontSize: "12px",
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => handleSelectPrompt(prompt)}
                    >
                      {prompt.name}
                    </MenuItem>
                  ))}
                </Scrollbars>
              ) : (
                <MenuItem disabled>No prompts available</MenuItem>
              )}
            </Select>
          </FormControl>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{
              display: "flex",
              fontSize: "14px",
              marginBottom: "8px",
              justifyContent: "space-between",
            }}
          >
            Instructions:
            {currentPrompt && (
              <Button
                variant="contained"
                color="success"
                startIcon={<FontAwesomeIcon icon={faHammer} />}
                sx={{
                  height: "30px",
                  minWidth: "30px",
                  textTransform: "none",
                }}
                onClick={() => {
                  navigate("/prompts", { state: { currentPrompt } });
                }}
              >
                Customize
              </Button>
            )}
          </Typography>
          <TextField
            rows={4}
            fullWidth
            multiline
            value={instructions}
            InputProps={{
              sx: { fontSize: "14px", marginTop: "2px", marginBottom: "8px" },
            }}
            onChange={(e) => {
              setCurrentPrompt(null);
              setInstructions(e.target.value);
            }}
            disabled={editMode && !isFieldEditable("instructions")}
          />
          {currentPrompt && variables.length > 0 && (
            <InputVariablesFields
              currentPrompt={currentPrompt}
              variables={variables}
              onUpdateVariable={handleUpdateVariables}
              disabled={editMode && !isFieldEditable("variables")}
            />
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={toggleFileList}
            sx={{
              mt: "5px",
              alignSelf: "center",
              marginBottom: "8px",
              textTransform: "none",
            }}
            startIcon={
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ fontSize: "12px" }}
              />
            }
          >
            <Typography sx={{ fontSize: "13px" }}>Document list</Typography>
          </Button>
          <Box
            {...getRootProps()}
            sx={{
              padding: 2,
              width: "100%",
              height: "80px",
              cursor: "pointer",
              marginTop: "10px",
              textAlign: "center",
              borderRadius: "4px",
              marginBottom: "8px",
              border: "2px dashed #ccc",
              backgroundColor: "#fafafa",
            }}
          >
            <IconButton
              sx={{
                marginTop: "-20px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CloudUpload />
            </IconButton>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography variant="body1">Drop the files here...</Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{ fontSize: "12px", marginTop: "-5px" }}
              >
                Click or drag file to this area to upload
              </Typography>
            )}
          </Box>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2, fontSize: "12px" }}>
              {errorMessage}
            </Alert>
          )}
          <Box
            sx={{
              height: "90%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-end",
              marginTop: isMobileOrTablet ? "30px" : "40px",
              marginBottom: isMobileOrTablet ? "5px" : "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobileOrTablet ? "column" : "row", // Vertical en móvil, horizontal en desktop
                gap: 2, // Espacio entre botones
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCreateOrUpdate}
                disabled={loadingAction}
                sx={{
                  width: "100%",
                  color: "white",
                  marginTop: "-30px",
                  alignSelf: "center",
                  textTransform: "none",
                  backgroundColor: "#0D92B7",
                  "&:hover": { backgroundColor: "#014a6d" },
                }}
                startIcon={
                  loadingAction ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ fontSize: "12px" }}
                    />
                  )
                }
              >
                <Typography sx={{ fontSize: "12px" }}>
                  {loadingAction
                    ? "Saving..."
                    : Object.keys(selectedAgent).length > 0
                    ? "Save"
                    : "Create agent"}
                </Typography>
              </Button>
              {Object.keys(selectedAgent).length > 0 && (
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  disabled={loadingAction}
                  sx={{
                    width: "100%",
                    color: "white",
                    marginTop: "-30px",
                    marginLeft: "40px",
                    alignSelf: "center",
                    textTransform: "none",
                    backgroundColor: "#D2122E",
                    "&:hover": { backgroundColor: "#b20000" },
                  }}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ fontSize: "12px" }}
                    />
                  }
                >
                  <Typography sx={{ fontSize: "12px" }}>Delete</Typography>
                </Button>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssistantDetails;
