export const playgroundStyles = {
  container: {
    display: "flex",
    height: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    transition: "all 0.3s ease-in-out",
    opacity: (hasSelectedAgent) => (hasSelectedAgent ? 1 : 0.5),
    pointerEvents: (hasSelectedAgent) => (hasSelectedAgent ? "auto" : "none"),
    filter: (hasSelectedAgent) =>
      hasSelectedAgent ? "none" : "grayscale(100%)",
    width: (slideOverOpen, isEmailCampaign) =>
      slideOverOpen
        ? `calc(100% - ${isEmailCampaign ? "60%" : "30%"})`
        : "100%",
    marginRight: (slideOverOpen, isEmailCampaign) =>
      slideOverOpen ? (isEmailCampaign ? "60%" : "30%") : 0,
  },
  noAgentMessage: {
    p: 2,
    top: "50%",
    left: "50%",
    boxShadow: 3,
    display: "flex",
    borderRadius: "8px",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    backgroundColor: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    fontSize: "15px",
    color: "#666",
  },
  campaignButton: {
    backgroundColor: "#0D92B7",
    "&:hover": { backgroundColor: "#014a6d" },
    textTransform: "none",
    fontSize: "13px",
  },
  buttonContainer: {
    padding: "8px 16px",
  },
  mobileContainer: {
    flexDirection: "column",
  },
  shareMenu: {
    "& .MuiPaper-root": {
      borderRadius: "6px",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    },
  },
  assistantDetails: {
    transition: "all 0.3s ease-in-out",
    width: (slideOverOpen, isEmailCampaign) =>
      slideOverOpen ? (isEmailCampaign ? "12%" : "20%") : "20%",
  },
  spinnerBox: {
    display: "flex",
    marginTop: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  chatBoxContainer: {
    padding: "8px 16px",
  },
  notificationDialog: {
    width: "100%",
  },
  confirmationDialog: {
    "& .MuiDialog-paper": {
      padding: "12px",
    },
  },
};
