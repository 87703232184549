export const slideOverStyles = {
  container: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    position: "fixed",
    background: "#fff",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    transform: (open) => (open ? "translateX(0)" : "translateX(100%)"),
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    boxShadow: "-4px 0 24px rgba(0, 0, 0, 0.12)",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px 0 0 16px",
  },
  header: {
    p: 2,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
    backgroundColor: "#fff",
    zIndex: 1,
    borderTopLeftRadius: "16px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    flex: 1,
    color: "#015d86",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "calc(100% - 120px)",
    position: "relative",
    padding: "16px",
    backgroundColor: "#fff",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    zIndex: 1,
  },
  textField: {
    "& .MuiInputBase-root": {
      height: "36px",
      transition: "all 0.2s ease-in-out",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.12)",
        transition: "all 0.2s ease-in-out",
      },
      "&:hover fieldset": {
        borderColor: "#015d86",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#015d86",
        borderWidth: "2px",
      },
    },
  },
  formSection: {
    mb: 3,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
  infoBox: {
    p: 2,
    mb: 3,
    borderRadius: 1,
    backgroundColor: "#f5f5f5",
    border: "1px solid #e0e0e0",
  },
  infoTitle: {
    mb: 1,
    color: "#0D92B7",
    fontWeight: "bold",
  },
  infoText: {
    fontSize: "12px",
    color: "#666",
    lineHeight: 1.5,
  },
  submitButton: {
    position: "absolute",
    bottom: 16,
    right: 16,
    backgroundColor: "#0D92B7",
    "&:hover": {
      backgroundColor: "#015d86",
    },
  },
  labelProps: {
    mt: "-5px",
    fontSize: "12px",
  },
  formContainer: {
    p: 2,
  },
  formTitle: {
    mb: 1,
    fontWeight: "bold",
  },
  emailFormSection: {
    gap: 2,
    display: "flex",
    justifyContent: "space-between",
  },
  emailFormColumn: {
    display: "flex",
    flexDirection: "column",
  },
  contactImportSection: {
    borderTop: "1px solid #eee",
    pt: 2,
    mt: 2,
  },
  contactSelectionButtons: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    "& .MuiButton-root": {
      margin: "8px 0",
    },
  },
  editModeHeader: {
    backgroundColor: "#e3f2fd",
    borderBottom: "1px solid #90caf9",
    transition: "background-color 0.3s ease",
  },
  editModeBorder: {
    borderLeft: "4px solid #015d86",
  },
  editModeTitle: {
    color: "#015d86",
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  editModeBanner: {
    p: 1,
    mb: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#e3f2fd",
    borderBottom: "1px solid #90caf9",
  },
};
