import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import { putFeedback } from "../../graphql/queries";

const options = [
  "Confusing answer",
  "Incorrect information",
  "Unnecessary repetition",
  "Delay in response",
  "Outdated data",
];

const FeedbackDialog = ({ show, data, handleClose }) => {
  const textFieldRef = useRef(null);
  const [comment, setComment] = useState("");

  const handleChipClick = (label) => {
    setComment(label);
    textFieldRef.current.focus();
  };

  const handleSubmit = async () => {
    const params = {
      ...data,
      comment: comment,
    };
    console.log("Feedback sent:", comment);
    try {
      const response = await API.graphql({
        query: putFeedback,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
    } catch (error) {
      console.error("Error sending feedback data:", error);
    }
    handleClose();
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>
        <Typography fontWeight="fontWeightBold">Tell us more:</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "5px",
            marginBottom: "25px",
          }}
        >
          {options.map((option, index) => (
            <Chip
              key={index}
              label={option}
              variant="outlined"
              sx={{
                cursor: "pointer",
                fontSize: "14px",
                fontFamily: "Arial, sans-serif",
              }}
              onClick={() => handleChipClick(option)}
            />
          ))}
        </Box>
        <TextField
          rows={5}
          autoFocus
          fullWidth
          multiline
          id="comment"
          value={comment}
          inputRef={textFieldRef}
          placeholder="Text here..."
          onChange={(e) => setComment(e.target.value)}
          InputProps={{
            sx: {
              color: "black",
              fontSize: "14px",
              borderRadius: "10px",
              "&.Mui-focused fieldset": {
                borderColor: "black !important",
              },
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ marginRight: "15px" }}>
        <Button sx={{ color: "#0D92B7" }} onClick={handleClose}>
          <Typography sx={{ fontSize: "13px" }}>Cancel</Typography>
        </Button>
        <Button
          sx={{
            color: "white",
            backgroundColor: "#0D92B7",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
          onClick={handleSubmit}
        >
          <Typography sx={{ fontSize: "13px" }}>Send</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
