import React from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

const FiltersModal = ({ open, onClose, filters, setFilters, applyFilters }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          id="filter-modal-title"
          variant="h6"
          sx={{ flexGrow: 1, fontWeight: "bold", mb: 3 }}
        >
          Filters
        </Typography>
        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Full Name
          </Typography>
          <TextField
            fullWidth
            name="name"
            value={filters.name}
            onChange={handleChange}
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "36px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Phone Number
          </Typography>
          <TextField
            fullWidth
            name="email"
            value={filters.email}
            onChange={handleChange}
            variant="outlined"
            sx={{
              "& .MuiInputBase-root": {
                height: "36px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Email
          </Typography>
          <TextField
            fullWidth
            name="phone"
            variant="outlined"
            value={filters.phone}
            onChange={handleChange}
            sx={{
              "& .MuiInputBase-root": {
                height: "36px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Date
          </Typography>
          <TextField
            fullWidth
            name="date"
            variant="outlined"
            value={filters.date}
            onChange={handleChange}
            sx={{
              "& .MuiInputBase-root": {
                height: "36px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
                "&:hover fieldset": {
                  borderColor: "black",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              },
              "& .MuiFormHelperText-root": {
                marginLeft: "0px",
              },
            }}
          />
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            flex={1}
            variant="contained"
            onClick={applyFilters}
            sx={{
              backgroundColor: "#0D92B7",
              "&:hover": { backgroundColor: "#015d86" },
            }}
          >
            Apply Filters
          </Button>
          <Button
            flex={1}
            variant="contained"
            onClick={onClose}
            sx={{
              color: "black",
              marginLeft: "10px",
              backgroundColor: "#D3D3D3",
              "&:hover": { backgroundColor: "#E5E4E2" },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FiltersModal;
