import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { API, Auth, Storage } from "aws-amplify";
import { faCircleDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Search, Upload } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteFiles, getFiles, putFiles } from "../../graphql/queries";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

const KnowledgeBase = ({ mode }) => {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [opacity, setOpacity] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [customerFromClaim, setCustomer] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alertMessage, setAlertMessage] = useState("");
  const [updateKey, setUpdateKey] = useState(Date.now());
  const [alertVisible, setAlertVisible] = useState(false);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const colorModeStyle = mode === "dark" ? "white" : "black";

  //   const textEllipsisStyles = {
  //     display: "block",
  //     overflow: "hidden",
  //     whiteSpace: "nowrap",
  //     textOverflow: "ellipsis",
  //     maxWidth: isMobile ? "calc(100% - 40px)" : "none",
  //   };

  useEffect(() => {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;

      Storage.configure({
        region: REGION,
        bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }
  }, []);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);
      } catch (e) {
        console.log("No current user", e);
      }
    };

    checkCustomer();
  }, [customerFromClaim]);

  useEffect(() => {
    if (alertMessage) {
      setAlertVisible(true);
      setOpacity(1);
      const fadeOutTimer = setTimeout(() => {
        const fadeEffect = setInterval(() => {
          setOpacity((prev) => {
            if (prev > 0) {
              return prev - 0.05;
            } else {
              clearInterval(fadeEffect);
              setAlertVisible(false);
              return 0;
            }
          });
        }, 200);
      }, 4000);
      return () => {
        clearTimeout(fadeOutTimer);
      };
    }
  }, [alertMessage]);

  useEffect(() => {
    const initialize = async () => {
      const user = await fetchUserId();
      if (user) {
        const applicantId = user.username;
        fetchFiles(applicantId);
      }
    };

    initialize();
  }, [updateKey]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredFiles = files.filter((file) =>
    file.file_name.toLowerCase().includes(search.toLowerCase())
  );

  const fetchUserId = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUserId(user.username);
      return user;
    } catch (error) {
      console.error("Error fetching user ID:", error);
    }
  };

  const fetchFiles = async (applicantId) => {
    console.log("Applicant ID:", applicantId);
    try {
      const response = await API.graphql({
        query: getFiles,
        variables: { applicant_id: applicantId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const fetchedFiles = JSON.parse(response.data.getFiles.body);
      console.log("Fetched files:", fetchedFiles);
      setFiles(fetchedFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const uploadFile = useCallback(
    async (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile.name.endsWith("case-study.docx")) {
        setAlertMessage(
          "You cannot upload a file with 'case-study.docx' format."
        );
        return;
      }
      setIsLoading(true);
      setAlertMessage("");
      console.log("selectedFile: ", selectedFile);
      const uploadedFiles = await handleFileUpload(event.target.files, userId);
      console.log("uploadedFiles: ", uploadedFiles);
      console.log("userId: ", userId);
      if (!_.isEmpty(uploadedFiles)) {
        await saveFilesToDatabase(uploadedFiles, userId);
      }
      setUpdateKey(Date.now());
      setIsLoading(false);
    },
    [userId, customerFromClaim]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          console.log("filePath: ", filePath);
          console.log("file: ", file);
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: (err) => {
              console.error("Unexpected error while uploading", err);
            },
          });
          console.log("applicantId: ", applicantId);
          console.log("result: ", result);
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      return uploadedFiles;
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const saveFilesToDatabase = async (uploadedFiles, applicantId) => {
    try {
      const response = await API.graphql({
        query: putFiles,
        variables: {
          user_id: applicantId,
          customer: customerFromClaim,
          s3_bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
          files: uploadedFiles,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("Response from putFiles:", response);
    } catch (error) {
      console.error("Error saving files to database:", error);
    }
  };

  const downloadFile = async (fileName) => {
    setIsLoading(true);
    try {
      const filePath = `internship/${userId}/${fileName}`;

      const fileUrl = await Storage.get(filePath, { level: "internship" });

      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error(`Error downloading file: ${fileName}`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteFileFromApp = async (file_id) => {
    if (
      !window.confirm(`Are you sure you want to delete the file: ${file_id}?`)
    ) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await API.graphql({
        query: deleteFiles,
        variables: {
          user: userId,
          files: [{ file_id: file_id }],
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      let body = JSON.parse(response.data.deleteFiles.body);
      console.log(
        `Delete File : ${response.data.deleteFiles.statusCode} - ${body.message}`
      );

      if (response.data.deleteFiles.statusCode === "200") {
        let filtered_files = _.filter(files, function (o) {
          return o.id !== file_id;
        });
        setFiles(filtered_files);
      }

      setUpdateKey(Date.now());
    } catch (error) {
      console.error(`Error deleting file: ${file_id}`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px",
        flex: "1 1 auto",
        backgroundColor: mode === "dark" ? "#2C2C2C" : "#F3F4F6",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: colorModeStyle,
            marginLeft: "40px",
            marginBottom: "-10px",
          }}
        >
          Knowledge Base File List <strong>&#9889;</strong>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<Upload />}
          sx={{
            marginLeft: "40px",
            backgroundColor: "#0D92B7",
            height: isMobileOrTablet ?? "30px",
            marginRight: isMobileOrTablet ?? "10px",
            "&:hover": { backgroundColor: "#015d86" },
          }}
        >
          <Typography sx={{ fontSize: isMobileOrTablet ? "10px" : "12px" }}>
            Upload File
          </Typography>
          <input type="file" hidden onChange={uploadFile} />
        </Button>
        <TextField
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={handleSearchChange}
          sx={{
            marginRight: "40px",
            marginBottom: "10px",
            borderRadius: "25px",
            "& .MuiOutlinedInput-root": {
              height: "36px",
              fontSize: "13px",
              borderRadius: "25px",
            },
          }}
          InputProps={{
            startAdornment: (
              <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            ),
            sx: {
              fontSize: "13px",
              paddingLeft: "8px",
              borderRadius: "25px",
            },
          }}
        />
      </Box>
      {isLoading && <LinearProgress style={{ marginTop: "10px" }} />}{" "}
      {alertVisible && (
        <Alert
          severity="error"
          sx={{
            opacity: opacity,
            maxWidth: "45%",
            marginTop: "10px",
            borderRadius: "8px",
          }}
        >
          {alertMessage}
        </Alert>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <TableContainer
          sx={{ maxHeight: "100%", marginLeft: "40px", marginRight: "40px" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: mode === "dark" ? "#333" : "#fafafa",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: mode === "dark" ? "#333" : "#fafafa",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: mode === "dark" ? "#333" : "#fafafa",
                  }}
                >
                  Download
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: mode === "dark" ? "#333" : "#fafafa",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((file, index) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor:
                          mode === "dark" ? "#3a3a3a" : "#f5f5f5",
                      },
                    }}
                  >
                    <TableCell>{file.file_name}</TableCell>
                    <TableCell>{file.file_description}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Download"
                        sx={{ marginLeft: "12px", color: "#0D92B7" }}
                        onClick={() => downloadFile(file.file_name)}
                      >
                        <FontAwesomeIcon icon={faCircleDown} />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Delete"
                        sx={{ color: "#D2122E" }}
                        onClick={() => deleteFileFromApp(file.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredFiles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ marginTop: "10px", marginRight: "40px" }}
      />
    </Paper>
  );
};

export default KnowledgeBase;
