import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  CircularProgress,
  Badge,
} from "@mui/material";
import { API } from "aws-amplify";
import { getConversations } from "../../../graphql/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import PropTypes from "prop-types";

const ConversationList = ({ campaign, account, onSelect }) => {
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  // Add console logs to check the types
  console.log("ConversationList - campaign:", {
    value: campaign,
    type: typeof campaign,
  });
  console.log("ConversationList - account:", {
    value: account,
    type: typeof account,
  });

  useEffect(() => {
    if (campaign) {
      fetchConversations(campaign, account);
    }
  }, [campaign, account]);

  useEffect(() => {
    if (!messages || _.isEmpty(messages)) return;

    try {
      console.log("Processing messages:", messages);

      // Group messages by conversation participants
      const conversationThreads = Object.values(
        messages.reduce((threads, msg) => {
          // Get both participants' emails
          const participant1 = msg.sender;
          const participant2 = msg.receiver;

          // Create a consistent thread key by sorting participant emails
          // This ensures messages between the same participants are grouped together
          // regardless of who is sender/receiver
          const threadKey = [participant1, participant2].sort().join("_");

          if (!threads[threadKey]) {
            // Use the non-agent email as the display email
            const displayEmail =
              msg.role === "INITIAL_EMAIL_SENT" ? msg.receiver : msg.sender;

            threads[threadKey] = {
              id: displayEmail,
              email: displayEmail,
              messages: [],
              lastMessage: "",
              timestamp: null,
              timestampStr: "",
              unread: false,
              messageCount: 0,
            };
          }

          // Add message to the thread
          const messageTime = new Date(msg.insert_date);
          const formattedMessage = {
            id: msg.id,
            content: msg.message,
            timestamp: messageTime,
            timestampStr: formatTimestamp(messageTime),
            // Set type based on role
            type: msg.role === "CONTACT" ? "contact" : "user",
            role: msg.role,
            sender: msg.sender,
            receiver: msg.receiver,
            status: msg.status,
          };

          threads[threadKey].messages.push(formattedMessage);
          threads[threadKey].messageCount += 1;

          // Update thread metadata if this is the latest message
          if (
            !threads[threadKey].timestamp ||
            messageTime > threads[threadKey].timestamp
          ) {
            threads[threadKey].lastMessage = msg.message;
            threads[threadKey].timestamp = messageTime;
            threads[threadKey].timestampStr = formatTimestamp(messageTime);
            threads[threadKey].unread = msg.status !== "SENT";
          }

          return threads;
        }, {})
      );

      // Sort messages within each thread by timestamp
      conversationThreads.forEach((thread) => {
        thread.messages = _.orderBy(thread.messages, ["timestamp"], ["asc"]);
      });

      console.log("Processed conversation threads:", conversationThreads);

      // Sort threads by latest message timestamp
      const orderedConversations = _.orderBy(
        conversationThreads,
        ["timestamp"],
        ["desc"]
      );
      setConversations(orderedConversations);
    } catch (error) {
      console.error("Error processing messages:", error);
    }
  }, [messages]);

  const fetchConversations = async (campaignId, accountId) => {
    setLoading(true);
    try {
      console.log("Fetching conversations for:", {
        campaignId,
        accountId,
      });

      const result = await API.graphql({
        query: getConversations,
        variables: { campaign: campaignId, customer: accountId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const body = JSON.parse(result.data.getConversations.body);

      // Add detailed debug logging here
      if (body.data?.messages) {
        console.log(
          "Raw messages sample:",
          body.data.messages.slice(0, 2).map((msg) => ({
            id: msg.id,
            direction: msg.direction,
            sender: msg.sender,
            receiver: msg.receiver,
            message: msg.message,
            insert_date: msg.insert_date,
          }))
        );
      }

      console.log("Conversations response:", body);

      if (!_.isUndefined(body.data) && !_.isEmpty(body.data)) {
        console.log("Conversations data structure:", {
          messages: body.data.messages,
        });

        const messagesList = Array.isArray(body.data.messages)
          ? body.data.messages
          : [];
        setMessages(messagesList);
      } else {
        console.log("No conversations found or empty response");
        setMessages([]);
      }
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const handleConversationClick = (conversation) => {
    onSelect(conversation);
  };

  const formatTimestamp = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleString();
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {conversations.length === 0 ? (
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ p: 2, textAlign: "center" }}
        >
          No conversations found
        </Typography>
      ) : (
        conversations.map((conversation, index) => {
          // Add a guard for undefined conversation or email
          const email = conversation?.email || "";
          const initial = email.length > 0 ? email[0].toUpperCase() : "?";

          return (
            <React.Fragment key={conversation?.id || index}>
              <ListItem
                alignItems="flex-start"
                onClick={() => handleConversationClick(conversation)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f8fafc",
                  },
                  borderRadius: 2,
                  py: 2,
                }}
              >
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    invisible={!conversation?.unread}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#ef4444",
                        border: "2px solid #ffffff",
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "#0ea5e9",
                        width: 48,
                        height: 48,
                        fontSize: "1.2rem",
                      }}
                    >
                      {initial}
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 600, color: "#1e293b" }}
                    >
                      {email}
                    </Typography>
                  }
                  secondary={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                        mt: 0.5,
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "250px",
                        }}
                      >
                        {conversation?.lastMessage || ""}
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <FontAwesomeIcon
                          icon={faComment}
                          style={{ fontSize: 12, color: "#64748b" }}
                        />
                        <Typography variant="body2" color="text.secondary">
                          {conversation?.timestampStr || ""}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
              {index < conversations.length - 1 && (
                <Divider component="li" sx={{ my: 1 }} />
              )}
            </React.Fragment>
          );
        })
      )}
    </List>
  );
};

ConversationList.propTypes = {
  campaign: PropTypes.string,
  account: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default ConversationList;
