import React from 'react';
import { Box, Paper, Typography, ButtonBase } from '@mui/material';
import { Email, Sms } from '@mui/icons-material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";

const CampaignTypeSelector = ({ onSelect, disabled }) => {
  const campaignTypes = [
    {
      id: 'email',
      title: 'Email Campaign',
      description: 'Create an email campaign with automated follow-ups',
      icon: <Email sx={{ fontSize: 32, color: '#015d86' }} />,
    },
    {
      id: 'sms',
      title: 'SMS Campaign',
      description: 'Set up an SMS campaign with AI-powered responses',
      icon: <Sms sx={{ fontSize: 32, color: '#015d86' }} />,
    }
  ];

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      p: 2,
      width: 'fit-content',
      margin: '0 auto',
      justifyContent: 'center'
    }}>
      {campaignTypes.map((type) => (
        <ButtonBase
          key={type.id}
          onClick={() => onSelect(type.id)}
          disabled={disabled}
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden',
            width: '280px',
            textAlign: 'left',
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 3,
              pb: 4,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              border: '1px solid rgba(0, 0, 0, 0.08)',
              transition: 'all 0.2s ease-in-out',
              backgroundColor: disabled ? '#f5f5f5' : '#fff',
              opacity: disabled ? 0.7 : 1,
              '&:hover': !disabled && {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                borderColor: '#015d86',
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}>
              {type.icon}
              <FontAwesomeIcon 
                icon={faRobot} 
                style={{ 
                  fontSize: 24,
                  color: '#015d86',
                  opacity: 0.7 
                }} 
              />
            </Box>
            <Box>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#015d86',
                  mb: 1 
                }}
              >
                {type.title}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  fontSize: '13px',
                  color: '#666',
                  lineHeight: 1.4 
                }}
              >
                {type.description}
              </Typography>
            </Box>
          </Paper>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default CampaignTypeSelector; 