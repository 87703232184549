import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { DndProvider } from "react-dnd";
import { GridLoader } from "react-spinners";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Tabs, Tab, Typography, Chip, Stack, TextField, Select } from "@mui/material";
import {
  faDiagramProject,
  faGear,
  faRotateRight,
  faCheck,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import GeneralTab from "./GeneralTab";
import RetriesTab from "./RetriesTab";
import VariablesTab from "./VariablesTab";
import EmailBuilder from "../../../components/EmailBuilder/EmailBuilder";
import campaignServiceInstance from "../../../services/campaign/campaignService";
import { API } from "aws-amplify";
import { getCampaignEmailRetryTemplate } from "../../../graphql/queries";
import { sharedFormStyles } from './styles/SharedFormStyles';
import LoadingAnimation from './LoadingAnimation';

const DraggableChip = ({ variable, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "VARIABLE",
    item: { value: variable.name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Chip
        label={variable.name}
        onClick={() => onDrop(variable.name)}
        sx={{
          backgroundColor: "#e3f2fd",
          "&:hover": {
            backgroundColor: "#90caf9",
          },
          cursor: "pointer",
        }}
      />
    </div>
  );
};

const EmailCampaignForm = ({
  senderName,
  onSenderNameChange,
  domain,
  onDomainChange,
  senderEmail,
  onSenderEmailChange,
  emailSubject,
  onEmailSubjectChange,
  isEditorLoading,
  isSending,
  selectedContacts,
  onEditorLoad,
  emailEditorRef,
  sampleDomainOptions,
  slideOverStyles,
  onSaveVariables,
  variableOptions,
  mappedFields,
  onSaveRetries,
  initialContent,
  initialRetries,
  initialVariables,
  editMode,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [emailContent, setEmailContent] = useState("");
  const [focusedField, setFocusedField] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [retries, setRetries] = useState(() => {
    if (editMode && initialRetries?.length > 0) {
      console.log('EmailCampaignForm: Initializing retries:', initialRetries);
      return initialRetries;
    }
    return [{ days: "", template: { name: "Current Template" } }];
  });
  const [variables, setVariables] = useState(() => {
    if (editMode && initialVariables?.length > 0) {
      console.log('Initializing variables with:', initialVariables);
      return initialVariables;
    }
    return [{ name: "", value: "" }];
  });
  const [design, setDesign] = useState(null);
  const [generalTabContent, setGeneralTabContent] = useState("");
  const [isGeneralEditorReady, setIsGeneralEditorReady] = useState(false);
  const [editorDesign, setEditorDesign] = useState(null);
  const [currentEmailContent, setCurrentEmailContent] = useState(initialContent || '');

  useEffect(() => {
    console.log('EmailCampaignForm Props:', {
      hasInitialContent: !!initialContent,
      initialContentValue: initialContent,
      activeTab,
      isEditorReady: !!emailEditorRef.current?.editor
    });
  }, [initialContent, activeTab, emailEditorRef.current]);

  useEffect(() => {
    if (onSaveVariables && typeof onSaveVariables === "function") {
      onSaveVariables(variables);
    }
  }, [variables, onSaveVariables]);

  useEffect(() => {
    if (editMode && initialRetries?.length > 0) {
      console.log('EmailCampaignForm: Updating retries from props:', initialRetries);
      setRetries(initialRetries);
    }
  }, [editMode, initialRetries]);

  useEffect(() => {
    if (editMode && initialContent) {
      console.log('Loading initial content in edit mode:', {
        hasContent: !!initialContent,
        isEditMode: editMode
      });

      // Convert HTML content to design format if needed
      try {
        // First try to parse as JSON design
        JSON.parse(initialContent);
        setCurrentEmailContent(initialContent);
        setGeneralTabContent(initialContent);
      } catch (e) {
        // If it's HTML, create a design structure
        const designStructure = {
          body: {
            rows: [
              {
                columns: [
                  {
                    contents: [
                      {
                        type: "text",
                        values: {
                          text: initialContent
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        };
        setCurrentEmailContent(JSON.stringify(designStructure));
        setGeneralTabContent(JSON.stringify(designStructure));
      }
    }
  }, [editMode, initialContent]);

  const handleAddRetry = async (days, index) => {
    try {
      console.log('Adding retry, preserving general content:', {
        hasGeneralContent: !!generalTabContent,
      });

      const newRetry = {
        days,
        template: {
          name: `Template ${retries.length}`,
          body: "",
          message: "",
        },
      };

      const updatedRetries = [...retries, newRetry];
      setRetries(updatedRetries);
      onSaveRetries(updatedRetries);
      setEmailTemplates((prev) => [...prev, `Template ${retries.length}`]);
    } catch (error) {
      console.error("Error creating retry:", error);
      throw error;
    }
  };

  const handleTabChange = (event, newValue) => {
    if (emailEditorRef.current?.editor) {
      console.log('Saving editor state before tab change');
      emailEditorRef.current.editor.saveDesign((design) => {
        console.log('Saved design state:', {
          hasDesign: !!design,
          isEditMode: editMode
        });
        setEditorDesign(design);
        // Store the complete design state
        const designJson = JSON.stringify(design);
        setCurrentEmailContent(designJson);
        setGeneralTabContent(designJson);
        setActiveTab(newValue);
      });
    } else {
      setActiveTab(newValue);
    }
  };

  const updateFirstRetryWithHtmlBody = async () => {
    if (!window.unlayer || !emailEditorRef?.current?.editor) {
      console.error("Unlayer editor not available");
      return;
    }

    // Save both design and HTML
    emailEditorRef.current.editor.saveDesign((design) => {
      emailEditorRef.current.editor.exportHtml(async (data) => {
        const validatedHtml = campaignServiceInstance.validateEmailContent(
          data.html
        );
        const bodyContent = validatedHtml.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
        const cleanBody = bodyContent
          ? bodyContent[1].replace(/\n/g, "").trim()
          : "";

        const updatedRetries = [...retries];
        updatedRetries[0] = {
          days: updatedRetries[0].days,
          template: {
            name: `Template 0`,
            body: validatedHtml,
            message: cleanBody,
            design: JSON.stringify(design) // Store the design for later editing
          },
        };

        setRetries(updatedRetries);
        onSaveRetries(updatedRetries);
      });
    });
  };

  const handleAddVariable = () => {
    setVariables([...variables, { name: "", value: "" }]);
  };

  const handleVariableChange = (newVariables) => {
    const validatedVariables = newVariables.map((variable) => ({
      name: variable.name.trim(),
      value: variable.value.trim(),
    }));

    setVariables(validatedVariables);
    if (onSaveVariables && typeof onSaveVariables === "function") {
      onSaveVariables(validatedVariables);
    }

    // Update email editor merge tags
    if (emailEditorRef.current?.editor) {
      const variableTags = {};
      validatedVariables.forEach((variable) => {
        if (variable.name && variable.value) {
          variableTags[variable.name] = {
            name: variable.name,
            value: `{{${variable.name}}}`,
            sample: variable.value,
          };
        }
      });
      emailEditorRef.current.editor.setMergeTags(variableTags);
    }
  };

  const handleDeleteVariable = (index) => {
    const newVariables = variables.filter((_, i) => i !== index);
    if (newVariables.length === 0) {
      setVariables([{ name: "", value: "" }]);
    } else {
      setVariables(newVariables);
    }
  };

  const handleChipClick = (variableValue) => {
    if (!focusedField) return;

    const value = `{{${variableValue}}}`;

    if (focusedField === "subject") {
      const cursorPosition =
        document.getElementById("email-subject").selectionStart;
      const currentValue = emailSubject;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onEmailSubjectChange({ target: { value: newValue } });
    } else if (focusedField === "senderName") {
      const cursorPosition =
        document.getElementById("sender-name").selectionStart;
      const currentValue = senderName;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onSenderNameChange({ target: { value: newValue } });
    }
  };

  const handleRetryChange = (index, field, value) => {
    console.log("Updating retry:", { index, field, value });

    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        let updatedRetry = { ...retry };

        switch (field) {
          case "days":
            updatedRetry.days = value;
            break;
          case "template":
            updatedRetry.template = {
              name: value.name || value,
              body: value.body || retry.template?.body,
              message: value.message || retry.template?.message,
            };
            break;
          case "body":
            updatedRetry.template = {
              ...retry.template,
              body: value,
              message: value,
            };
            break;
          default:
            break;
        }
        return updatedRetry;
      }
      return retry;
    });

    console.log("Updated retries:", updatedRetries);

    setRetries(updatedRetries);
    onSaveRetries(updatedRetries);
  };

  const handleDeleteRetry = (index) => {
    console.log("Deleting retry at index:", index);

    const updatedRetries = retries.filter((_, i) => i !== index);

    if (updatedRetries.length === 0) {
      updatedRetries.push({
        days: "",
        template: {
          name: "Current Template",
          body: "",
          message: "",
        },
      });
    }

    setRetries(updatedRetries);
    onSaveRetries(updatedRetries);

    setEmailTemplates((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRetryBody = async (body) => {
    if (!window.unlayer || !emailEditorRef?.current?.editor) {
      console.error("Unlayer editor not available");
      return;
    }

    try {
      // Get both design and HTML
      const [design, htmlData] = await Promise.all([
        new Promise((resolve) => emailEditorRef.current.editor.saveDesign(resolve)),
        new Promise((resolve) => emailEditorRef.current.editor.exportHtml(resolve))
      ]);

      const response = await API.graphql({
        query: getCampaignEmailRetryTemplate,
        variables: {
          originalTemplate: htmlData.html,
          retryMessage: body,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const responseBody = JSON.parse(
        response.data.getCampaignEmailRetryTemplate.body
      );

      if (responseBody.data) {
        const updatedRetries = [...retries];
        const lastRetry = updatedRetries[updatedRetries.length - 1];
        if (lastRetry) {
          lastRetry.template = {
            ...lastRetry.template,
            body: responseBody.data,
            design: JSON.stringify(design) // Store the design for editing
          };
        }
        setRetries(updatedRetries);
        onSaveRetries(updatedRetries);
      }

      return responseBody.data;
    } catch (error) {
      console.error("Error generating retry template:", error);
      throw error;
    }
  };

  const handleEditorLoad = (editor) => {
    console.log('Editor Load Start:', {
      activeTab,
      hasInitialContent: !!initialContent,
      isEditMode: editMode,
      hasEditorDesign: !!editorDesign
    });
    
    setIsGeneralEditorReady(true);

    if (activeTab === 0) {
      // Determine which content to load
      let contentToLoad;
      if (editorDesign) {
        contentToLoad = editorDesign;
      } else if (initialContent || currentEmailContent) {
        contentToLoad = initialContent || currentEmailContent;
      }

      if (contentToLoad) {
        console.log('Loading content into editor:', {
          hasContent: !!contentToLoad,
          isEditMode: editMode,
          type: typeof contentToLoad
        });

        try {
          // If contentToLoad is already a design object, use it directly
          if (typeof contentToLoad === 'object') {
            editor.loadDesign(contentToLoad);
          } else {
            // Try to parse as JSON design first
            const design = JSON.parse(contentToLoad);
            editor.loadDesign(design);
          }
        } catch (e) {
          // Fallback to loading as text content
          editor.loadDesign({
            body: {
              rows: [
                {
                  cells: [1],
                  columns: [
                    {
                      contents: [
                        {
                          type: "text",
                          values: {
                            text: contentToLoad
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          });
        }
      }

      // Add design:updated listener
      editor.addEventListener("design:updated", () => {
        editor.saveDesign((design) => {
          setEditorDesign(design);
          const designJson = JSON.stringify(design);
          setCurrentEmailContent(designJson);
          setGeneralTabContent(designJson);
        });
      });
    }

    if (onEditorLoad) {
      onEditorLoad(editor);
    }
  };

  useEffect(() => {
    if (activeTab === 0 && emailEditorRef.current?.editor && editorDesign) {
      console.log('Restoring editor content after tab switch:', {
        hasDesign: !!editorDesign,
        isEditMode: editMode
      });
      
      emailEditorRef.current.editor.loadDesign(editorDesign);
    }
  }, [activeTab, editorDesign, editMode]);

  useEffect(() => {
    if (editMode && emailEditorRef.current?.editor) {
      const editor = emailEditorRef.current.editor;
      
      const handleDesignUpdate = () => {
        editor.saveDesign((design) => {
          console.log('Content updated in edit mode');
          setEditorDesign(design);
          const designJson = JSON.stringify(design);
          setCurrentEmailContent(designJson);
          setGeneralTabContent(designJson);
        });
      };

      editor.addEventListener('design:updated', handleDesignUpdate);

      return () => {
        editor.removeEventListener('design:updated', handleDesignUpdate);
      };
    }
  }, [editMode, emailEditorRef.current]);

  useEffect(() => {
    console.log("Retries state updated:", retries);
  }, [retries]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ height: "100%", minHeight: "100%" }}
        >
          <Tab
            label="General"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faGear}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
          <Tab
            label="Variables"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faDiagramProject}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
          <Tab
            label="Retries"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faRotateRight}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
        </Tabs>
      </Box>
      {activeTab === 0 ? (
        <GeneralTab
          senderName={senderName}
          onSenderNameChange={onSenderNameChange}
          domain={domain}
          onDomainChange={onDomainChange}
          senderEmail={senderEmail}
          onSenderEmailChange={onSenderEmailChange}
          emailSubject={emailSubject}
          onEmailSubjectChange={onEmailSubjectChange}
          isSending={isSending}
          sampleDomainOptions={sampleDomainOptions}
          onSenderNameFocus={() => setFocusedField("senderName")}
          onSenderNameBlur={() => setFocusedField(null)}
          onSubjectFocus={() => setFocusedField("subject")}
          onSubjectBlur={() => setFocusedField(null)}
        />
      ) : activeTab === 1 ? (
        <VariablesTab
          variables={variables}
          options={variableOptions}
          handleVariableChange={handleVariableChange}
          handleAddVariable={handleAddVariable}
          handleDeleteVariable={handleDeleteVariable}
          mappedFields={mappedFields}
          editMode={editMode}
        />
      ) : (
        <RetriesTab
          retries={retries}
          handleRetryChange={handleRetryChange}
          handleDeleteRetry={handleDeleteRetry}
          onAddRetry={handleAddRetry}
          emailTemplates={emailTemplates}
          generalTabContent={generalTabContent}
          editMode={editMode}
          variables={variables}
          onSaveRetries={(newRetries) => {
            console.log('RetriesTab: Saving retries:', newRetries);
            setRetries(newRetries);
            onSaveRetries(newRetries);
          }}
        />
      )}
      {activeTab !== 2 && (
        <Box
          sx={{ p: 2, borderBottom: "1px solid rgba(0, 0, 0, 0.12)", mt: -2 }}
        >
          <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
            Available Variables
          </Typography>
          {variables[0].name !== "" ? (
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {variables.map((variable, index) => (
                <DraggableChip
                  key={index}
                  variable={variable}
                  onDrop={handleChipClick}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No variables have been created yet. Create them in the Variables
              tab.
            </Typography>
          )}
        </Box>
      )}
      {isEditorLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10,
          }}
        >
          <LoadingAnimation message="Loading editor..." />
        </Box>
      )}
      {activeTab !== 2 && (
        <EmailBuilder
          variables={variables}
          selectedContacts={selectedContacts}
          onEditorLoad={handleEditorLoad}
          exportHtml={emailEditorRef}
          initialContent={currentEmailContent || initialContent || generalTabContent}
          options={{
            features: {
              textEditor: {
                default: true,
                spellChecker: true,
              },
            },
            appearance: {
              theme: "white",
            },
            tools: {
              text: {
                enabled: true,
                default: true,
              },
            },
          }}
        />
      )}
    </DndProvider>
  );
};

export default EmailCampaignForm;
