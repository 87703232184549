import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import Scrollbars from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faEye,
  faEyeSlash,
  faRotateRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import EmailBuilder from "../../../components/EmailBuilder/EmailBuilder";
import { getCampaignEmailRetryTemplate } from "../../../graphql/queries";
import { Info } from "@mui/icons-material";

const RetriesTab = ({
  retries,
  handleRetryChange,
  handleDeleteRetry,
  onAddRetry,
  emailTemplates,
  generalTabContent,
  editMode,
  onSaveRetries,
}) => {
  const retryEditorRefs = useRef([]);
  const [daysInput, setDaysInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [retryContents, setRetryContents] = useState({});
  const [visibleEmailBuilderIndex, setVisibleEmailBuilderIndex] =
    useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    isError: false,
  });
  const [generatingRetries, setGeneratingRetries] = useState({});

  useEffect(() => {
    console.log("RetriesTab: Received retries:", {
      retries,
      count: retries?.length,
      editMode,
      isVersioned: retries[0]?.template?.body?.v1 !== undefined,
    });

    const newContents = {};
    retries.forEach((retry, index) => {
      if (editMode && retry.template?.body?.v1) {
        const template = retry.template?.body || {};
        Object.keys(template).forEach((key) => {
          if (key.match(/^v\d+$/)) {
            const versionIndex = `${index}-${key}`;
            newContents[versionIndex] = template[key].template;
          }
        });
      } else {
        newContents[index] =
          retry.template?.body || retry.template?.message || generalTabContent;
      }
    });
    setRetryContents(newContents);
  }, [retries, generalTabContent, editMode]);

  const createRetryEmailTemplate = async (index, days = null) => {
    const currentRetry = retries[index];

    // Log the content being used for retry generation
    console.log("Creating retry template with content:", {
      generalTabContent,
      currentRetryTemplate: currentRetry.template,
      days: days || currentRetry.days,
    });

    const params = {
      unit: "day",
      value: days || currentRetry.days,
      message: generalTabContent, // This should be HTML content from the main editor
    };

    try {
      const response = await API.graphql({
        query: getCampaignEmailRetryTemplate,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const responseBody = JSON.parse(
        response.data.getCampaignEmailRetryTemplate.body
      );

      console.log("Retry template API response:", responseBody);

      // Update the retry with the new template
      handleRetryChange(index, "template", {
        name: currentRetry.template.name,
        body: responseBody.data,
        message: responseBody.data,
      });

      setRetryContents((prev) => ({
        ...prev,
        [index]: responseBody.data,
      }));

      return responseBody.data;
    } catch (error) {
      console.error("Error generating retry template:", error);
      throw error;
    }
  };

  const handleEditorLoad = (editor, index) => {
    console.log(`Editor loading for retry ${index}`, {
      retryTemplate: retries[index]?.template?.name,
      hasContent: !!retryContents[index],
    });

    if (!retryEditorRefs.current) {
      retryEditorRefs.current = [];
    }

    retryEditorRefs.current[index] = {
      editor,
      exportHtml: () =>
        new Promise((resolve) => {
          editor.exportHtml((data) => resolve(data.html));
        }),
    };

    const content =
      retryContents[index] ||
      retries[index]?.template?.body ||
      retries[index]?.template?.message;

    if (content) {
      console.log(`Loading content for retry ${index}:`, {
        contentLength: content.length,
        source: retries[index]?.template?.name,
        type: retries[index]?.template?.body ? "body" : "message",
      });

      editor.loadDesign({
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "text",
                      values: {
                        text: content,
                        textAlign: "left",
                        lineHeight: "140%",
                        fontSize: "14px",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      });
    }

    editor.addEventListener("design:updated", () => {
      editor.exportHtml((data) => {
        const newContent = data.html;
        setRetryContents((prev) => ({
          ...prev,
          [index]: newContent,
        }));
        handleRetryChange(index, "body", newContent);
      });
    });
  };

  const handleRetryContentChange = (index, editor) => {
    if (editor) {
      editor.exportHtml((data) => {
        setRetryContents((prev) => ({
          ...prev,
          [index]: data.html,
        }));
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleAddRetryClick = async (days, index) => {
    if (!days) {
      setSnackbar({
        open: true,
        message: "Please add a value in Days input",
        isError: true,
      });
      return;
    }

    try {
      if (editMode && retries[0]?.template?.body?.v1) {
        // For edit mode with versioned retries
        const newRetry = {
          days: days,
          template: {
            name: `Template ${retries.length}`,
            body: {
              v1: {
                template: "",
                message: "",
                days: days,
              },
            },
          },
        };
        const updatedRetries = [...retries, newRetry];
        onSaveRetries(updatedRetries);
      } else {
        // For new campaign or non-versioned retries
        await onAddRetry(days, index);
      }

      // Clear modal state after successful creation
      setOpenModal(false);
      setDaysInput("");
    } catch (error) {
      console.error("Error adding retry:", error);
      setSnackbar({
        open: true,
        message: "Error creating retry template",
        isError: true,
      });
    }
  };

  const handleRetryDelete = (index) => {
    console.log("🗑️ Deleting retry at index", index);
    console.log("📦 RetryContents before deletion:", retryContents);

    setRetryContents((prev) => {
      const newContents = { ...prev };
      delete newContents[index];
      Object.keys(newContents).forEach((key) => {
        if (Number(key) > index) {
          newContents[Number(key) - 1] = newContents[key];
          delete newContents[key];
        }
      });
      return newContents;
    });

    handleDeleteRetry(index);
  };

  const handleTemplateSelect = (index, selectedTemplate) => {
    const templateObject = {
      name: selectedTemplate.name || selectedTemplate,
      body:
        selectedTemplate.body ||
        (selectedTemplate === "Current Template"
          ? generalTabContent
          : selectedTemplate.body),
    };

    handleRetryChange(index, "template", templateObject);

    const newContent =
      templateObject.name === "Current Template"
        ? generalTabContent
        : templateObject.body;

    setRetryContents((prev) => ({
      ...prev,
      [index]: newContent,
    }));
  };

  const handleGenerateRetry = async (index, versionKey = null) => {
    const currentRetry = retries[index];
    const days = versionKey
      ? currentRetry.template.body[versionKey].days
      : currentRetry.days;

    if (!days) {
      setSnackbar({
        open: true,
        message: "Please add a value in Days input",
        isError: true,
      });
      return;
    }

    try {
      // Set loading state with the correct key for versioned retries
      const loadingKey = versionKey ? `${index}-${versionKey}` : index;
      setGeneratingRetries((prev) => ({ ...prev, [loadingKey]: true }));

      // Log the content being used for generation
      console.log("Generating retry with content:", {
        generalTabContent,
        days,
        isHTML:
          typeof generalTabContent === "string" &&
          generalTabContent.includes("<"),
        contentLength: generalTabContent?.length,
      });

      if (editMode && currentRetry.template?.body?.v1) {
        // For versioned retries
        const template = await createRetryEmailTemplate(index, days);
        const updatedRetry = {
          ...currentRetry,
          template: {
            ...currentRetry.template,
            body: {
              ...currentRetry.template.body,
              [versionKey]: {
                ...currentRetry.template.body[versionKey],
                template,
                message: template,
                days,
              },
            },
          },
        };
        const updatedRetries = [...retries];
        updatedRetries[index] = updatedRetry;
        onSaveRetries(updatedRetries);
      } else {
        // For regular retries
        await createRetryEmailTemplate(index);
      }

      setSnackbar({
        open: true,
        message: "Retry template generated successfully",
        isError: false,
      });
    } catch (error) {
      console.error("Error generating retry template:", error);
      setSnackbar({
        open: true,
        message: "Error generating retry template",
        isError: true,
      });
    } finally {
      // Clear loading state with the correct key
      const loadingKey = versionKey ? `${index}-${versionKey}` : index;
      setGeneratingRetries((prev) => ({ ...prev, [loadingKey]: false }));
    }
  };

  const handleEyeButtonClick = (index) => {
    setVisibleEmailBuilderIndex(
      visibleEmailBuilderIndex === index ? null : index
    );
  };

  const handleDeleteVersion = (retryIndex, versionKey) => {
    console.log("Deleting version:", { retryIndex, versionKey });

    // Create a copy of retries
    const updatedRetries = [...retries];
    const retry = updatedRetries[retryIndex];

    // Remove the specific version from template body
    if (retry.template?.body) {
      const updatedBody = { ...retry.template.body };
      delete updatedBody[versionKey];

      // Update the retry with the new body
      updatedRetries[retryIndex] = {
        ...retry,
        template: {
          ...retry.template,
          body: updatedBody,
        },
      };

      // If no more versions exist, delete the entire retry
      if (
        Object.keys(updatedBody).filter((key) => key.match(/^v\d+$/)).length ===
        0
      ) {
        updatedRetries.splice(retryIndex, 1);
      }

      console.log("Updated retries after deletion:", updatedRetries);
      onSaveRetries(updatedRetries);
    }
  };

  useEffect(() => {
    return () => {
      retryEditorRefs.current.forEach((ref) => {
        if (ref?.editor) {
          ref.editor.removeEventListener("design:updated");
        }
      });
    };
  }, []);

  const renderRetryContent = () => {
    if (editMode && retries[0]?.template?.body?.v1) {
      return retries.map((retry, retryIndex) => {
        const template = retry.template?.body || {};
        const versions = Object.keys(template).filter((key) =>
          key.match(/^v\d+$/)
        );

        return (
          <Box key={retryIndex}>
            {versions.map((versionKey) => {
              const versionIndex = `${retryIndex}-${versionKey}`;
              const versionContent = template[versionKey].template;

              return (
                <Box
                  key={versionIndex}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mb: 3,
                    backgroundColor: "#f8f9fa",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #e0e0e0",
                      paddingBottom: "12px",
                    }}
                  >
                    {/* Left side - Title and Days */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        sx={{ color: "#015d86" }}
                      >
                        {retry.template?.name || `Retry ${retryIndex + 1}`} -
                        Version {versionKey}
                      </Typography>

                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <TextField
                          value={retry.template.body[versionKey].days || ""}
                          onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                              const updatedRetry = { ...retry };
                              updatedRetry.template.body[versionKey].days =
                                e.target.value;
                              handleRetryChange(
                                retryIndex,
                                "template",
                                updatedRetry.template
                              );
                            }
                          }}
                          placeholder="Days"
                          size="small"
                          sx={{
                            width: "80px",
                            "& .MuiOutlinedInput-root": {
                              height: "36px",
                              backgroundColor: "white",
                            },
                          }}
                        />
                        <IconButton
                          onClick={() =>
                            handleGenerateRetry(retryIndex, versionKey)
                          }
                          sx={{
                            color: "#43a047",
                            backgroundColor: "white",
                            "&:hover": { backgroundColor: "#f1f8f1" },
                            "&.Mui-disabled": {
                              backgroundColor: "#f5f5f5",
                            },
                          }}
                          disabled={
                            !retry.template.body[versionKey].days ||
                            generatingRetries[`${retryIndex}-${versionKey}`]
                          }
                          size="small"
                        >
                          <Tooltip title="Regenerate retry template">
                            <FontAwesomeIcon
                              icon={faRotateRight}
                              className={
                                generatingRetries[`${retryIndex}-${versionKey}`]
                                  ? "fa-spin"
                                  : ""
                              }
                            />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Box>

                    {/* Right side - Action Buttons */}
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        onClick={() =>
                          handleDeleteVersion(retryIndex, versionKey)
                        }
                        sx={{
                          color: "#d32f2f",
                          backgroundColor: "white",
                          "&:hover": { backgroundColor: "#fef2f2" },
                        }}
                        size="small"
                      >
                        <Tooltip title="Delete this version">
                          <FontAwesomeIcon icon={faTrash} />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() => handleEyeButtonClick(versionIndex)}
                        sx={{
                          color: "#015d86",
                          backgroundColor: "white",
                          "&:hover": { backgroundColor: "#f0f7fa" },
                        }}
                        size="small"
                      >
                        <Tooltip
                          title={
                            visibleEmailBuilderIndex === versionIndex
                              ? "Hide template"
                              : "See template"
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              visibleEmailBuilderIndex === versionIndex
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Email Builder */}
                  {visibleEmailBuilderIndex === versionIndex && (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        mt: 2,
                      }}
                    >
                      <Box
                        sx={{
                          height: "500px",
                          border: "1px solid #e0e0e0",
                          borderRadius: "6px",
                          overflow: "hidden",
                        }}
                      >
                        <EmailBuilder
                          onEditorLoad={(editor) =>
                            handleEditorLoad(editor, versionIndex)
                          }
                          exportHtml={(ref) =>
                            (retryEditorRefs.current[versionIndex] = ref)
                          }
                          variables={[]}
                          initialContent={versionContent}
                          options={{
                            features: {
                              textEditor: {
                                default: true,
                                spellChecker: true,
                              },
                            },
                            appearance: {
                              theme: "white",
                            },
                            tools: {
                              text: {
                                enabled: true,
                                default: true,
                              },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        );
      });
    } else {
      // Render regular retries for new campaign
      return retries.map(
        (retry, index) =>
          retry.template?.name !== "Current Template" && (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mb: 3,
                backgroundColor: "#f8f9fa",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "12px",
                }}
              >
                {/* Left side - Title and Days */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: "#015d86" }}
                  >
                    {retry.template?.name || `Retry ${index + 1}`}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <TextField
                      value={retry.days}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          handleRetryChange(index, "days", e.target.value);
                        }
                      }}
                      placeholder="Days"
                      size="small"
                      sx={{
                        width: "80px",
                        "& .MuiOutlinedInput-root": {
                          height: "36px",
                          backgroundColor: "white",
                        },
                      }}
                    />
                    <IconButton
                      onClick={() => handleGenerateRetry(index)}
                      sx={{
                        color: "#43a047",
                        backgroundColor: "white",
                        "&:hover": { backgroundColor: "#f1f8f1" },
                        "&.Mui-disabled": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      disabled={!retry.days || generatingRetries[index]}
                      size="small"
                    >
                      <Tooltip title="Generate retry template">
                        <FontAwesomeIcon
                          icon={faRotateRight}
                          className={generatingRetries[index] ? "fa-spin" : ""}
                        />
                      </Tooltip>
                    </IconButton>
                  </Box>
                </Box>

                {/* Right side - Action Buttons */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  {retries.length > 1 && (
                    <IconButton
                      onClick={() => handleDeleteRetry(index)}
                      sx={{
                        color: "#d32f2f",
                        backgroundColor: "white",
                        "&:hover": { backgroundColor: "#fef2f2" },
                      }}
                      size="small"
                    >
                      <Tooltip title="Delete retry">
                        <FontAwesomeIcon icon={faCircleMinus} />
                      </Tooltip>
                    </IconButton>
                  )}
                  {retry.template.body !== "" &&
                    retry.template?.name !== "Current Template" && (
                      <IconButton
                        onClick={() => handleEyeButtonClick(index)}
                        sx={{
                          color: "#015d86",
                          backgroundColor: "white",
                          "&:hover": { backgroundColor: "#f0f7fa" },
                        }}
                        size="small"
                      >
                        <Tooltip
                          title={
                            visibleEmailBuilderIndex === index
                              ? "Hide template"
                              : "See template"
                          }
                        >
                          <FontAwesomeIcon
                            icon={
                              visibleEmailBuilderIndex === index
                                ? faEyeSlash
                                : faEye
                            }
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                </Box>
              </Box>

              {/* Email Builder */}
              {index === visibleEmailBuilderIndex && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      height: "500px",
                      border: "1px solid #e0e0e0",
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                  >
                    <EmailBuilder
                      onEditorLoad={(editor) => handleEditorLoad(editor, index)}
                      exportHtml={(ref) =>
                        (retryEditorRefs.current[index] = ref)
                      }
                      variables={[]}
                      initialContent={retryContents[index]}
                      options={{
                        features: {
                          textEditor: {
                            default: true,
                            spellChecker: true,
                          },
                        },
                        appearance: {
                          theme: "white",
                        },
                        tools: {
                          text: {
                            enabled: true,
                            default: true,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )
      );
    }
  };

  const RetryInstructionBanner = () => (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 2,
        p: 2,
        mb: 2,
        backgroundColor: "#f0f7fa",
        borderRadius: "8px",
        border: "1px solid #90caf9",
        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
      }}
    >
      <Info sx={{ color: "#015d86", fontSize: 24, mt: 0.5 }} />
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#015d86",
            fontWeight: 600,
            mb: 1,
            fontSize: "0.95rem",
          }}
        >
          How to Generate a Retry Template
        </Typography>
        <Typography
          component="div"
          variant="body2"
          sx={{
            color: "#2c5282",
            fontSize: "0.875rem",
            lineHeight: 1.5,
          }}
        >
          <Box component="p" sx={{ m: 0, mb: 1 }}>
            1. Click the "Create New Retry" button to add a new retry template
          </Box>
          <Box component="p" sx={{ m: 0, mb: 1 }}>
            2. Enter the number of days to wait before sending the retry
          </Box>
          <Box component="p" sx={{ m: 0, mb: 1 }}>
            3. Click the refresh icon to generate a template based on your
            original email
          </Box>
          <Box component="p" sx={{ m: 0, mb: 1 }}>
            4. Review and customize the generated template if needed
          </Box>
          <Box
            component="p"
            sx={{ m: 0, color: "#4a5568", fontStyle: "italic" }}
          >
            Pro tip: Our AI generates optimized follow-up content, but you can
            always modify it to match your style and needs
          </Box>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ p: 2, height: "100%" }}>
      <RetryInstructionBanner />
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", mb: 3 }}
      >
        <Button
          variant="contained"
          onClick={() => setOpenModal(true)}
          sx={{
            backgroundColor: "#015d86",
            "&:hover": { backgroundColor: "#014a6d" },
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>Create New Retry</Typography>
        </Button>
      </Box>
      <Scrollbars style={{ height: "520px", width: "100%" }}>
        {renderRetryContent()}
      </Scrollbars>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Enter Days</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Days"
            type="number"
            fullWidth
            variant="outlined"
            value={daysInput}
            onChange={(e) => setDaysInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              if (daysInput) {
                handleAddRetryClick(daysInput, retries.length);
              }
            }}
            disabled={!daysInput}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: snackbar.isError ? "#d32f2f" : "#43a047",
          },
        }}
      />
    </Box>
  );
};

export default RetriesTab;
