export const FETCH_RETRIES = 2;

export const ERROR_MESSAGES = {
  PROMPT_NAME_REQUIRED: "Prompt Name is required.",
  INSTRUCTIONS_REQUIRED: "Instructions are required.",
  VARIABLES_INCOMPLETE:
    "All input variables must have both a name and a value.",
  SAVE_ERROR: "An error occurred while saving the prompt. Please try again.",
  DELETE_ERROR:
    "An error occurred while deleting the prompt. Please try again.",
};

export const DEFAULT_PROMPT_STATE = {
  name: "",
  description: "",
  human_instruction: "",
  tags: [],
  input_variables: [{ name: "", value: "" }],
};

export const FIELD_HEIGHTS = {
  DEFAULT: "36px",
  MULTILINE: "100px",
};

export const COLORS = {
  PRIMARY: "#0D92B7",
  PRIMARY_HOVER: "#015d86",
  ERROR: "#D2122E",
};

export const ALERT_MESSAGE =
  "To use input variables, enclose the part of your instructions that needs to vary inside curly brackets {variable}. For example, {location}. You will be able to set the values for these variables in the Playground when using the prompt.";
