import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  LinearProgress,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AttachFile, PermMedia, Send } from "@mui/icons-material";
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStore from "../../hooks/useStore";
import SuggestedQuestionsButton from "./SuggestedQuestionsButton";
import KnowlegdeBaseList from "../KnowlegdeBaseList/KnowlegdeBaseList";

const switchStyles = {
  "& .MuiSwitch-switchBase": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
};

const formControlLabelStyles = {
  width: "150px",
  marginLeft: "2px",
  "& .MuiTypography-root": {
    fontSize: "12px",
    marginTop: "3px",
  },
};

const ChatBox = ({
  isDarkMode,
  files,
  isAdmin,
  isGroqAgent,
  showSpinner,
  currentMessage,
  uploadedStatus,
  isUploadingFile,
  onUploadFile,
  onUpdateMessages,
  onSendMessage,
  clearMessages,
  handleModelType,
  isSlideOverOpen,
}) => {
  const { authStore } = useStore();
  const textFieldRef = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const [model, setModel] = useState("INSTANT");
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [isFileListVisible, setFileListVisible] = useState(false);

  const fontColorStyle = isDarkMode ? "white" : "black";
  const backgroundColorStyle = isDarkMode ? "#272727" : "#fff";

  useEffect(() => {
    if (authStore.isLoading) {
      authStore.getGlobalInformation();
    }
  }, [authStore]);

  useEffect(() => {
    if (uploadedStatus) {
      setAlertVisible(true);
      setOpacity(1);
      const fadeOutTimer = setTimeout(() => {
        const fadeEffect = setInterval(() => {
          setOpacity((prev) => {
            if (prev > 0) {
              return prev - 0.05;
            } else {
              clearInterval(fadeEffect);
              setAlertVisible(false);
              return 0;
            }
          });
        }, 200);
      }, 4000);
      return () => {
        clearTimeout(fadeOutTimer);
      };
    }
  }, [uploadedStatus]);

  useEffect(() => {
    if (!showSpinner) {
      textFieldRef.current.focus();
    }
  }, [showSpinner]);

  useEffect(() => {
    if (selectedQuestion && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [selectedQuestion]);

  const toggleFileList = useCallback(() => {
    setFileListVisible((prev) => !prev);
  }, []);

  const handleQuestionClick = useCallback(
    (item) => {
      onUpdateMessages(item.text);
      setSelectedQuestion(item.text);
    },
    [onUpdateMessages]
  );

  const handleModelChange = (event) => {
    const newModel = event.target.checked ? "VERSATILE" : "INSTANT";
    setModel(newModel);
    handleModelType(newModel);
  };

  const handleSendMessage = useCallback(() => {
    onSendMessage();
  }, [onSendMessage]);

  return (
    <Box
      sx={{
        height: "22%",
        backgroundColor: backgroundColorStyle,
        transition: "all 0.3s ease-in-out",
        width: isSlideOverOpen ? "70%" : "100%",
      }}
    >
      <Divider
        sx={{
          backgroundColor: isDarkMode ? "white" : "#BDC3C760",
        }}
      />
      {isUploadingFile && <LinearProgress />}
      <KnowlegdeBaseList
        open={isFileListVisible}
        onClose={toggleFileList}
        files={files}
      />
      <Box
        sx={{
          margin: "25px",
          display: "flex",
          padding: "12px",
          marginBottom: "20px",
          flexDirection: "column",
          border: "1px solid #ccc",
          backgroundColor: backgroundColorStyle,
          transition: "all 0.3s ease-in-out",
        }}
      >
        <TextField
          autoFocus
          multiline
          fullWidth
          maxRows={10}
          variant="outlined"
          inputRef={textFieldRef}
          placeholder="Type your message..."
          value={currentMessage}
          onChange={(e) => onUpdateMessages(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !showSpinner) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          InputProps={{
            sx: {
              color: fontColorStyle,
              fontSize: "14px",
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent !important",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent !important",
              },
              "&.Mui-disabled": {
                "& fieldset": {
                  borderColor: "transparent !important",
                },
              },
            },
            style: {
              resize: "none",
              borderRadius: "25px",
              borderColor: "transparent !important",
              backgroundColor: backgroundColorStyle,
            },
          }}
          disabled={showSpinner}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "25px",
            marginLeft: "15px",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Clear Chat" arrow placement="top">
              <Button
                variant="contained"
                component="label"
                sx={{
                  width: "0px",
                  height: "31px",
                  display: "flex",
                  minWidth: "0px",
                  marginTop: "3px",
                  borderRadius: "20px",
                  backgroundColor: "#0D92B7",
                  "&:hover": {
                    backgroundColor: "#1a749c",
                  },
                }}
                onClick={clearMessages}
              >
                <FontAwesomeIcon icon={faBroom} />
              </Button>
            </Tooltip>
            {isAdmin && isGroqAgent && (
              <FormControlLabel
                control={
                  <Tooltip title="Change model" arrow placement="top">
                    <Switch
                      sx={switchStyles}
                      onChange={handleModelChange}
                      checked={model === "VERSATILE"}
                    />
                  </Tooltip>
                }
                label={
                  <Typography
                    fontWeight="bold"
                    variant="subtitle1"
                    sx={{ color: "#015d86" }}
                  >
                    {model}
                  </Typography>
                }
                sx={formControlLabelStyles}
              />
            )}
          </Box>
          <Box
            sx={{
              gap: 1,
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
            }}
          >
            <SuggestedQuestionsButton
              handleSelectQuestion={handleQuestionClick}
            />
            <Button
              disabled={showSpinner}
              onClick={handleSendMessage}
              sx={{
                mt: "3px",
                minWidth: "20px",
                borderRadius: "25px",
              }}
            >
              {showSpinner ? (
                <CircularProgress size={24} />
              ) : (
                <Send sx={{ color: "#0D92B7" }} />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatBox;
