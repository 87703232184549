import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Alert,
  Snackbar,
  Slide,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  Close,
  ArrowBack,
  Warning,
  Edit,
  PersonAdd,
} from "@mui/icons-material";
import SMSCampaignForm from "./SMSCampaignForm";
import EmailCampaignForm from "./EmailCampaignForm";
import { getCustomer } from "../../../graphql/queries";
import ContactsSelectionTable from "./ContactsSelectionTable";
import ContactSelectionButtons from "./ContactSelectionButton";
import { slideOverStyles } from "./styles/CampaignSlideOver.styles";
import campaignService from "../../../services/campaign/campaignService";
import ContactImport from "../../../components/ContactImport/ContactImport";
import { Info } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const SlideTransition = React.forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CampaignSlideOver = ({
  open,
  type,
  agentId,
  onClose,
  userId,
  customerFromClaim,
  editMode,
  campaignData,
  onCampaignCreated,
}) => {
  const emailEditorRef = useRef();
  const [error, setError] = useState(null);
  const [domain, setDomain] = useState("");
  const [retries, setRetries] = useState([]);
  const [variables, setVariables] = useState([]);
  const [senderName, setSenderName] = useState("");
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [senderEmail, setSenderEmail] = useState("");
  const [userDomains, setUserDomains] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [emailSubject, setEmailSubject] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [mappedFields, setMappedFields] = useState(null);
  const [selectionMode, setSelectionMode] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [retryTemplates, setRetryTemplates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState("");
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);
  const [generalTabContent, setGeneralTabContent] = useState(null);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
    icon: null,
  });
  const [isContactSelectionOpen, setIsContactSelectionOpen] = useState(false);
  const [userCustomerAccount, setUserCustomerAccount] = useState(null);

  const isEmail = type === "email";

  useEffect(() => {
    if (!open) {
      resetState();
    } else if (editMode && campaignData) {
      // Load campaign data when editing
      console.log("campaignData: ", campaignData);
      if (type === "email") {
        console.log("CampaignSlideOver: Campaign data for editing:", {
          campaignData,
          hasContext: !!campaignData.context,
          contextContent: campaignData.context,
          hasEditor: !!emailEditorRef.current?.editor,
          hasFirstMessage: !!campaignData.first_message,
          hasRetries: !!campaignData.retries?.length,
          retriesCount: campaignData.retries?.length || 0,
        });

        setCampaignId(campaignData.campaignId.replace("campaign#", ""));
        setSenderName(campaignData.senderName || "");
        setSenderEmail(campaignData.senderEmail || "");
        setEmailSubject(campaignData.emailSubject || "");

        // Initialize variables
        if (campaignData.variables?.length > 0) {
          console.log("Setting initial variables:", campaignData.variables);
          setVariables(campaignData.variables);
        }

        // Initialize retries from campaign data
        if (campaignData.retries?.length > 0) {
          console.log(
            "Setting retries from campaign data:",
            campaignData.retries
          );
          const formattedRetries = campaignData.retries.map((retry, index) => ({
            days: retry.days || String(index),
            template: {
              name: retry.template?.name || `Template ${index}`,
              body: retry.template?.body || retry.message || "",
              message: retry.template?.message || retry.message || "",
            },
          }));
          setRetries(formattedRetries);
        } else if (campaignData.first_message) {
          // Only set first message as retry if no retries exist
          setRetries([
            {
              days: "0",
              template: {
                name: "Current Template",
                body: campaignData.first_message,
                message: campaignData.first_message,
              },
            },
          ]);
        }

        // Load the email HTML content
        const contentToLoad =
          campaignData.first_message ||
          campaignData.context ||
          campaignData.emailHtml;
        if (contentToLoad && emailEditorRef.current?.editor) {
          console.log("CampaignSlideOver: Loading HTML into Email Editor:", {
            contentSource: campaignData.first_message
              ? "first_message"
              : campaignData.context
              ? "context"
              : "emailHtml",
            hasEditor: !!emailEditorRef.current.editor,
          });

          emailEditorRef.current.editor.loadDesign({
            html: contentToLoad,
          });
        }

        if (campaignData.selectedContacts) {
          setSelectedContacts(campaignData.selectedContacts);
        }

        if (campaignData.mappedFields) {
          setMappedFields(campaignData.mappedFields);
        }
      } else if (type === "sms") {
        setCampaignName(campaignData.name || "");
        setPhoneNumber(campaignData.phoneNumber || "");
      }
    }
  }, [open, editMode, campaignData, type]);

  useEffect(() => {
    if (open && !editMode) {
      setCampaignId(uuidv4());
    }
  }, [open]);

  useEffect(() => {
    if (open && agentId) {
      fetchUserDomains().catch((error) => {
        setError(error.message);
        console.error("Failed to fetch domains:", error);
      });
    }
  }, [open, agentId]);

  useEffect(() => {
    if (emailEditorRef.current?.editor) {
      console.log("emailEditorRef: ", emailEditorRef.current?.editor);
      emailEditorRef.current.editor.exportHtml((data) => {
        console.log("Initial email HTML content:", {
          html: data.html,
          design: data.design,
        });
        setCurrentTemplate(data.html);
        setGeneralTabContent(data.html);
      });
    }
  }, [emailEditorRef.current]);

  useEffect(() => {
    const getUserCustomerAccount = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const userAttributes = currentUser.attributes;
        const customerAccount = userAttributes["custom:account"];
        setUserCustomerAccount(customerAccount);
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    getUserCustomerAccount();
  }, []);

  const resetState = () => {
    setSelectedContacts([]);
    setIsEditorLoading(true);
    setError(null);
    setSuccessMessage("");
    setEmailSubject("");
  };

  const updateVariables = () => {
    if (variables?.length && emailEditorRef?.current?.editor) {
      const variablesToSet = {};
      for (const variable of variables) {
        variablesToSet[variable.value] = {
          name: variable.name,
          value: `{{${variable.value}}}`,
          sample: `{{${variable.value}}}`,
        };
      }

      emailEditorRef?.current?.editor?.setMergeTags(variablesToSet);
    }
  };

  const handleBack = () => {
    setIsContactSelectionOpen(false);
  };

  const handleEditorLoad = () => {
    updateVariables();
    setIsEditorLoading(false);
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.exportHtml((data) => {
        console.log("Editor loaded - HTML content:", {
          html: data.html,
          design: data.design,
        });
        setGeneralTabContent(data.html);
      });
    }
  };

  const handleContactsImport = (contacts, file, mappedFields) => {
    if (!file.name.toLowerCase().endsWith(".csv")) {
      setError("Only CSV files are allowed");
      return;
    }

    const headers = Object.values(mappedFields).filter(Boolean);
    setCsvHeaders(headers);
    setSelectedContacts((prev) => [...prev, ...contacts]);
    setCurrentFile(file);
    setMappedFields(mappedFields);
    setIsContactSelectionOpen(false);
  };

  const updateEmailAddress = (name, domain) => {
    const email = name && domain ? `${name}@${domain}` : "";
    setSenderEmail(email);
  };

  const handleSenderNameChange = (event) => {
    const name = event.target.value.replace(/[^a-zA-Z ]/g, "");
    setSenderName(name);
    updateEmailAddress(name, domain);
  };

  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setDomain(selectedDomain);
    updateEmailAddress(senderName, selectedDomain);
  };

  const handleSenderEmailChange = (event) => {
    setSenderEmail(event.target.value);
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const handleSendCampaign = async (isDraft = false) => {
    try {
      setError(null);
      setIsSending(true);

      if (!emailSubject.trim()) {
        throw new Error("Please enter an email subject");
      }

      // Get the current email content
      let emailHtml = (await emailEditorRef.current()) || generalTabContent;

      // Create a map of variable names to values
      const variableMap = variables.reduce((acc, v) => {
        acc[v.name.trim()] = v.value.trim();
        return acc;
      }, {});

      console.log("Variable mapping:", variableMap);

      const variableRegex = /\{\{([^}]+)\}\}/g;

      // Replace variables in subject, sender name and email content
      const processedSubject = emailSubject.replace(
        variableRegex,
        (match, name) => {
          const value = variableMap[name.trim()];
          return value ? value : match;
        }
      );

      const processedSenderName = senderName.replace(
        variableRegex,
        (match, name) => {
          const value = variableMap[name.trim()];
          return value ? value : match;
        }
      );

      // Replace variables in email content
      emailHtml = emailHtml?.replace(variableRegex, (match, name) => {
        const value = variableMap[name.trim()];
        return value ? value : match;
      });

      const campaignData = {
        agentId,
        campaignId,
        status: isDraft ? "draft" : "active",
        emailSubject: processedSubject,
        emailHtml,
        selectedContacts,
        senderEmail: process.env.REACT_APP_SES_SENDER_EMAIL || senderEmail,
        senderName: processedSenderName,
        contactsFile: currentFile,
        mappedFields,
        selectionMode,
        retries,
        variables,
        customerAccount: userCustomerAccount,
      };

      console.log(
        `${isDraft ? "Drafting" : "Sending"} campaign with status:`,
        isDraft ? "draft" : "active",
        {
          isDraft,
          campaignData,
          customerAccount: userCustomerAccount,
        }
      );

      const result = await campaignService.createAndSendCampaign(campaignData);

      console.log("Campaign operation result:", {
        isDraft,
        result,
        status: isDraft ? "draft" : "active",
        customerAccount: userCustomerAccount,
      });

      // Show notification
      setNotification({
        open: true,
        message: isDraft
          ? "Campaign saved as draft successfully"
          : `Campaign sent successfully to ${result.recipientCount} recipients`,
        severity: "success",
        icon: <FontAwesomeIcon icon={faCheckCircle} />,
      });

      // Add delay before closing for both draft and sent cases
      setTimeout(
        () => {
          onClose();
          navigate("/campaigns");
        },
        isDraft ? 1500 : 2000
      ); // Slightly shorter delay for drafts

      setSuccessMessage("Campaign created successfully!");
      if (onCampaignCreated) {
        onCampaignCreated();
      }
    } catch (error) {
      console.error(
        `Campaign ${isDraft ? "draft" : "send"} operation failed:`,
        {
          error: error.message,
          isDraft,
          customerAccount: userCustomerAccount,
          timestamp: new Date().toISOString(),
        }
      );

      setNotification({
        open: true,
        message:
          error.message ||
          `Failed to ${isDraft ? "save" : "send"} campaign. Please try again.`,
        severity: "error",
        icon: <FontAwesomeIcon icon={faExclamationCircle} />,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleSMSCampaignSubmit = async () => {
    try {
      console.log("🚀 Starting SMS campaign creation...", {
        campaignId,
        timestamp: new Date().toISOString(),
      });

      setError(null);
      setIsSending(true);

      if (!campaignName.trim()) {
        console.warn("❌ Validation failed: Campaign name is empty");
        throw new Error("Campaign name is required");
      }

      if (!phoneNumber.trim()) {
        console.warn("❌ Validation failed: Phone number is empty");
        throw new Error("Phone number is required");
      }

      // Validate phone number format
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(phoneNumber.trim())) {
        console.warn("❌ Validation failed: Invalid phone number format", {
          phoneNumber: phoneNumber.trim(),
        });
        throw new Error("Invalid phone number format");
      }

      const campaignData = {
        id: campaignId,
        name: campaignName.trim(),
        phoneNumber: phoneNumber.trim(),
        agentId: agentId,
        type: "inbound",
        customerAccount: userCustomerAccount,
      };

      console.log("📦 SMS Campaign data prepared:", {
        campaignId: campaignData.id,
        campaignName: campaignData.name,
        phoneNumber: campaignData.phoneNumber,
        agentId: campaignData.agentId,
        type: campaignData.type,
        timestamp: new Date().toISOString(),
        customerAccount: userCustomerAccount,
      });

      const response = await campaignService.createSMSCampaign(campaignData);

      console.log("✅ SMS Campaign created successfully:", {
        campaignId: campaignData.id,
        response,
        timestamp: new Date().toISOString(),
      });

      setSuccessMessage("SMS Campaign created successfully");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("❌ SMS Campaign creation failed:", {
        campaignId,
        error: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString(),
      });
      setError(error.message || "Failed to create SMS campaign");
    } finally {
      setIsSending(false);
    }
  };

  const renderContactSelection = () => {
    if (!selectionMode) {
      return (
        <ContactSelectionButtons
          onSelectMode={setSelectionMode}
          containerStyles={slideOverStyles.contactSelectionButtons}
        />
      );
    }

    return selectionMode === "table" ? (
      <>
        <Button
          variant="text"
          sx={{ ml: 2 }}
          startIcon={<ArrowBack />}
          onClick={() => setSelectionMode(null)}
        >
          Back to selection
        </Button>
        <ContactsSelectionTable
          onNext={() => setIsContactSelectionOpen(false)}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
        />
      </>
    ) : (
      <Box sx={{ p: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => setSelectionMode(null)}
          sx={{
            mb: 2,
            color: "#015d86",
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>Back to selection</Typography>
        </Button>

        <Alert
          severity="info"
          icon={<Warning />}
          sx={{
            mb: 3,
            backgroundColor: "#e3f2fd",
            "& .MuiAlert-icon": {
              color: "#1976d2",
            },
            border: "1px solid #90caf9",
            borderRadius: "4px",
          }}
        >
          <Box>
            <Typography fontWeight="bold" sx={{ fontSize: "14px", mb: 0.5 }}>
              CSV Files Only
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Please note that only CSV (.csv) files are supported for contact
              imports. Other file formats will be rejected.
            </Typography>
          </Box>
        </Alert>
        <ContactImport
          onImportComplete={handleContactsImport}
          acceptedFileTypes={{
            "text/csv": [".csv"],
          }}
          onError={(error) => setError(error.message)}
        />

        {error && (
          <Alert
            severity="error"
            sx={{
              mt: 2,
              "& .MuiAlert-message": {
                fontSize: "13px",
              },
            }}
          >
            {error}
          </Alert>
        )}
      </Box>
    );
  };

  const getVariableOptions = () => {
    if (selectionMode === "table" && selectedContacts?.length > 0) {
      // For table selection, get fields from the contact object
      const excludedFields = ["metadata", "insert_date", "last_modified"];
      return Object.keys(selectedContacts[0] || {}).filter(
        (key) => !excludedFields.includes(key)
      );
    } else if (currentFile && mappedFields) {
      // For CSV import, use the mapped headers
      return csvHeaders;
    }
    return [];
  };

  const fetchUserDomains = async () => {
    try {
      const params = { id: customerFromClaim, status: "" };
      const response = await API.graphql({
        query: getCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const currentUserCustomer = JSON.parse(response.data.getCustomer.body);

      const userDomains = currentUserCustomer.channels.EMAIL || [];
      const smsNumbers = currentUserCustomer.channels.SMS || [];

      console.log("Extracted channel data:", {
        userDomains,
        smsNumbers,
        domainsLength: userDomains.length,
        smsNumbersLength: smsNumbers.length,
        timestamp: new Date().toISOString(),
      });

      setUserDomains(userDomains);
      setUserPhoneNumbers(smsNumbers);
      setDomain(userDomains[0]);
      if (smsNumbers.length > 0) {
        setPhoneNumber(smsNumbers[0]);
      }

      return userDomains;
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserDomains([]);
      setUserPhoneNumbers([]);
      setDomain("");
      throw new Error(
        "Unable to load configuration. Please ensure domains and phone numbers are configured in your settings."
      );
    }
  };

  const handleTemplateGenerated = (newTemplate, newSubject) => {
    // Update the email editor with new template
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.loadDesign({ html: newTemplate });
    }

    // Update subject if provided
    if (newSubject) {
      setEmailSubject(newSubject);
    }

    // Store the template
    setEmailTemplates((prev) => [...prev, newTemplate]);
    setRetryTemplates((prev) => [...prev, newTemplate]);
  };

  const getCampaignTitle = () => {
    if (!campaignData) return "Untitled";

    if (isEmail) {
      return campaignData.emailSubject || "Untitled Email Campaign";
    }
    return campaignData.name || "Untitled SMS Campaign";
  };

  const ContactSelectionModal = () => (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "white",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 1001,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Select Contacts</Typography>
          <IconButton onClick={() => setIsContactSelectionOpen(false)}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          p: 2,
        }}
      >
        {renderContactSelection()}
      </Box>
    </Box>
  );

  return (
    <SlideTransition in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          ...slideOverStyles.container,
          width: editMode ? "100%" : isEmail ? "50%" : "30%",
          borderLeft: editMode
            ? "4px solid #015d86"
            : "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            ...slideOverStyles.header,
            backgroundColor: editMode ? "#e3f2fd" : "#fff",
          }}
        >
          {isEmail && (
            <IconButton onClick={handleBack} size="small" sx={{ mr: 1 }}>
              <ArrowBack />
            </IconButton>
          )}
          <Typography
            sx={{
              ...slideOverStyles.title,
              color: editMode ? "#015d86" : "inherit",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {editMode && <Edit sx={{ fontSize: 20 }} />}
            {editMode
              ? `Edit ${isEmail ? "Email" : "SMS"} Campaign`
              : `${isEmail ? "Email" : "SMS"} Campaign`}
          </Typography>

          {editMode ? (
            <Button
              onClick={() => {
                onClose();
                navigate("/playground");
              }}
              variant="contained"
              size="small"
              sx={{
                mr: 2,
                backgroundColor: "#015d86",
                "&:hover": { backgroundColor: "#014a6d" },
                textTransform: "none",
              }}
            >
              Exit Edit Mode
            </Button>
          ) : (
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          )}
        </Box>

        {editMode && (
          <Box
            sx={{
              p: 1,
              mb: 1,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#e3f2fd",
              borderBottom: "1px solid #90caf9",
            }}
          >
            <Info sx={{ mr: 1, color: "#015d86" }} />
            <Typography
              variant="body2"
              sx={{
                color: "#015d86",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>You are editing campaign:</span>
              <span style={{ fontWeight: "bold", marginTop: "2px" }}>
                {getCampaignTitle()}
              </span>
            </Typography>
          </Box>
        )}

        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}

        {successMessage && (
          <Snackbar
            open={!!successMessage}
            autoHideDuration={4000}
            onClose={() => setSuccessMessage("")}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSuccessMessage("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>
        )}

        <Box
          sx={
            selectionMode
              ? slideOverStyles.content
              : {
                  display: "block",
                  ...slideOverStyles.content,
                }
          }
        >
          {isEmail ? (
            <>
              <EmailCampaignForm
                senderName={senderName}
                onSenderNameChange={handleSenderNameChange}
                domain={domain}
                onDomainChange={handleDomainChange}
                senderEmail={senderEmail}
                onSenderEmailChange={handleSenderEmailChange}
                emailSubject={emailSubject}
                onEmailSubjectChange={(e) => setEmailSubject(e.target.value)}
                isEditorLoading={isEditorLoading}
                isSending={isSending}
                selectedContacts={selectedContacts}
                onEditorLoad={handleEditorLoad}
                emailEditorRef={emailEditorRef}
                sampleDomainOptions={userDomains}
                slideOverStyles={slideOverStyles}
                variableOptions={getVariableOptions()}
                mappedFields={mappedFields}
                currentTemplate={currentTemplate}
                onTemplateGenerated={handleTemplateGenerated}
                onSaveRetries={(newRetries) => {
                  console.log("Saving retries:", newRetries);
                  setRetries(newRetries);
                }}
                emailTemplates={emailTemplates}
                generalTabContent={generalTabContent}
                initialContent={
                  campaignData?.context || campaignData?.emailHtml
                }
                initialRetries={retries}
                initialVariables={variables}
                editMode={editMode}
              />
              <Box
                sx={{
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  display: "flex",
                  gap: 2,
                  zIndex: 1000,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => setIsContactSelectionOpen(true)}
                  startIcon={<PersonAdd />}
                  sx={{
                    backgroundColor: "white",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                  }}
                >
                  {selectedContacts.length > 0
                    ? `Selected Contacts (${selectedContacts.length})`
                    : "Select Contacts"}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSendCampaign(true)}
                  disabled={isSending || selectedContacts.length === 0}
                >
                  Save as Draft
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSendCampaign(false)}
                  disabled={isSending || selectedContacts.length === 0}
                >
                  Send Campaign
                </Button>
              </Box>
            </>
          ) : (
            <SMSCampaignForm
              campaignName={campaignName}
              setCampaignName={setCampaignName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              isSending={isSending}
              onSubmit={handleSMSCampaignSubmit}
              phoneNumbers={userPhoneNumbers}
            />
          )}
        </Box>

        {isContactSelectionOpen && <ContactSelectionModal />}

        <Snackbar
          open={notification.open}
          autoHideDuration={4000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={SlideTransition}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            icon={notification.icon}
            sx={{
              width: "100%",
              minWidth: "300px",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
              "& .MuiAlert-icon": {
                fontSize: "1.2rem",
                alignItems: "center",
                marginRight: "12px",
              },
              "& .MuiAlert-message": {
                fontSize: "0.95rem",
                fontWeight: 500,
              },
              ...(notification.severity === "success" && {
                backgroundColor: "#E7F6ED",
                color: "#1B874B",
                "& .MuiAlert-icon": {
                  color: "#1B874B",
                },
              }),
              ...(notification.severity === "error" && {
                backgroundColor: "#FEF2F2",
                color: "#DC2626",
                "& .MuiAlert-icon": {
                  color: "#DC2626",
                },
              }),
            }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </SlideTransition>
  );
};

export default CampaignSlideOver;
