import React, { useCallback, useState, useEffect } from "react";
import _ from "lodash";
import { Auth } from "aws-amplify";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  faAddressCard,
  faChartColumn,
  faComments,
  faDatabase,
  faDice,
  faEnvelopeOpenText,
  faFileLines,
  faPenToSquare,
  faUserAstronaut,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { PropagateLoader } from "react-spinners";
import { ThemeProvider } from "@mui/material/styles";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import "./App.css";
// import ErrorPage from "./error";
import useStore from "./hooks/useStore";
import Contacts from "./pages/Contacts";
import Campaigns from "./pages/Campaigns";
import Customers from "./pages/Customers";
import Interviews from "./pages/Interviews";
import Playground from "./pages/Playground";
import TopBar from "./components/TopBar/TopBar";
import CaseStudyViewer from "./pages/CaseStudy";
// import PrivateRoute from "./routes/PrivateRoute";
import KnowledgeBase from "./pages/KnowlegdeBase";
import Sidebar from "./components/Sidebar/Sidebar";
import CampaignViewer from "./pages/viewer/viewer";
import SignIn from "./pages/Authentication/SignIn";
import AdminAssistant from "./pages/AdminAssistant";
import OnboardingView from "./pages/OnboardingView";
import PromptDashboard from "./pages/PromptDashboard";
import InternshipAssistant from "./pages/InternshipAssistant";
import ConfirmSignUp from "./pages/Authentication/ConfirmSignUp";
import SignUpInvitation from "./pages/Authentication/SignUpInvitation";

Auth.configure({
  authenticationFlowType: "CUSTOM_AUTH",
});

const toolbarTitles = {
  "/chat": { title: "Internship Assistant", icon: faUserAstronaut },
  "/admin-chat": { title: "Admin Assistant", icon: faUserTie },
  "/playground": { title: "Playground", icon: faDice },
  "/prompts": { title: "Prompt Builder", icon: faPenToSquare },
  "/interviews": { title: "Interviews", icon: faComments },
  "/case-study": { title: "Case Study Viewer", icon: faFileLines },
  "/customers": { title: "Customers", icon: faUsers },
  "/contacts": { title: "Contacts", icon: faAddressCard },
  "/campaigns": { title: "Campaigns", icon: faEnvelopeOpenText },
  "/metrics": { title: "Metrics", icon: faChartColumn },
  "/conversations": { title: "Conversations", icon: faUserAstronaut },
  "/": { title: "Base", icon: faDatabase },
};

const getToolbarInfo = (pathname) => toolbarTitles[pathname] || "";

const App = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useStore();
  const [isAdmin, setIsAdmin] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userState, setUserState] = useState("noLogged");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isStoreLoaded, setIsStoreLoaded] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  console.log("userState: ", userState);

  const { title, icon } = getToolbarInfo(location.pathname);

  useEffect(() => {
    const fetchStore = async () => {
      if (authStore.isLoading) {
        await authStore.getGlobalInformation();
        await authStore.fetchFiles(authStore.userId);
        await authStore.fetchAgents(authStore.userId);
        const { isSuperAdmin } = authStore;
        if (isSuperAdmin !== null) {
          setIsStoreLoaded(true);
        }
      }
    };

    fetchStore();
  }, [authStore]);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const cognitoGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        setIsAdmin(
          !_.isUndefined(cognitoGroups) && cognitoGroups[0] === "superadmin"
            ? true
            : false
        );
        console.log("Current user:", user);
        setUserState("logged");
        setCognitoUser(user);
      } catch (e) {
        console.log("No current user", e);
        setUserState("noLogged");
      }
    };

    checkCurrentUser();
  }, [userState]);

  const handleToggleTheme = () => {
    setIsDarkMode(isDarkMode ? false : true);
  };

  const handleUserState = (state) => {
    setUserState(state);
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
  };

  const handleDrawerToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSignOut = useCallback(() => {
    Auth.signOut()
      .then((data) => {
        navigate("/signin");
        setUserState("noLogged");
      })
      .catch((error) => console.log("error signing out: ", error));
  }, [navigate]);

  const AuthenticatedLayout = () => (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          maxWeight: "60px",
          borderRight: "1px solid #ddd",
          width: isMobileOrTablet ? "0%" : "60px",
          display: isMobileOrTablet ? "none" : "block",
        }}
      >
        <Sidebar
          isDarkMode={isDarkMode}
          isAdmin={isAdmin}
          onSendMessageToChatWindow={handleQuestionClick}
          onQuestionClick={handleQuestionClick}
          mobileOpen={isSidebarOpen}
          handleDrawerToggle={handleDrawerToggle}
          handleSignOut={handleSignOut}
          toggleTheme={handleToggleTheme}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopBar
          isDarkMode={isDarkMode}
          title={title}
          icon={icon}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );

  if (!isStoreLoaded) {
    return (
      <Box
        sx={{
          right: "50%",
          height: "100%",
          display: "flex",
          position: "fixed",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PropagateLoader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Routes>
          {/* <Route
            path="/onboarding"
            element={<OnboardingView userId={authStore.userId} />}
          /> */}
          <Route
            path="/invitation"
            element={<SignUpInvitation isSuperAdmin={isAdmin} />}
          />
          <Route path="/confirmation" element={<ConfirmSignUp />} />
          {userState === "noLogged" ? (
            <>
              <Route
                path="/signin"
                element={<SignIn onSignIn={handleUserState} />}
              />
              <Route path="/" element={<Navigate to="/signin" />} />
            </>
          ) : (
            <>
              <Route element={<AuthenticatedLayout />}>
                {/* <Route
                  path="/chat"
                  element={<InternshipAssistant isDarkMode={isDarkMode} />}
                /> */}
                {/* <Route
                  path="/admin-chat"
                  element={
                    <AdminAssistant
                      files={authStore.files}
                      isDarkMode={isDarkMode}
                    />
                  }
                /> */}
                <Route
                  path="/playground"
                  element={<Playground isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/prompts"
                  element={<PromptDashboard isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/interviews"
                  element={<Interviews isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/case-study"
                  element={<CaseStudyViewer isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/"
                  element={
                    <KnowledgeBase
                      selectedQuestion={selectedQuestion}
                      isDarkMode={isDarkMode}
                    />
                  }
                />
                <Route
                  path="/customers"
                  element={<Customers isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/contacts"
                  element={<Contacts isDarkMode={isDarkMode} />}
                />
                <Route
                  path="/campaigns"
                  element={<Campaigns isDarkMode={isDarkMode} />}
                />
                <Route path="/signin" element={<Navigate to="/" />} />
                <Route path="/conversations" element={<CampaignViewer />} />
              </Route>
              <Route path="/" element={<Navigate to="/chat" />} />
            </>
          )}
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
