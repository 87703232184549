import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Box, Typography } from "@mui/material";

const EmailBuilder = ({ 
  variables, 
  selectedContacts, 
  onEditorLoad, 
  exportHtml,
  initialContent,
  options = {} 
}) => {
  const editorRef = useRef(null);
  const [isEditorInitialized, setIsEditorInitialized] = useState(false);

  useEffect(() => {
    console.log('EmailBuilder received props:', {
      hasInitialContent: !!initialContent,
      initialContent,
      isEditorInitialized,
      hasUnlayer: !!window.unlayer
    });
  }, [initialContent, isEditorInitialized]);

  useEffect(() => {
    const editorElement = document.getElementById("editor");

    const initializeEditor = () => {
      console.log('Initializing email editor');
      if (
        editorElement &&
        !editorElement.hasAttribute("data-unlayer-initialized")
      ) {
        window.unlayer.init({
          id: "editor",
          projectId: 256104,
          displayMode: "email",
        });
        editorElement.setAttribute("data-unlayer-initialized", "true");
        setIsEditorInitialized(true);
        console.log('Editor initialized successfully');

        if (onEditorLoad) {
          setTimeout(() => {
            console.log('Calling onEditorLoad callback');
            onEditorLoad(window.unlayer);
          }, 100);
        }
      }
    };

    if (!window.unlayer) {
      if (
        !document.querySelector(
          'script[src="https://editor.unlayer.com/embed.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://editor.unlayer.com/embed.js";
        script.async = true;
        script.onload = initializeEditor;
        document.body.appendChild(script);
      }
    } else {
      initializeEditor();
    }

    return () => {
      if (window.unlayer) {
        window.unlayer.removeEventListener('design:updated');
        window.unlayer.destroy();
        if (editorElement) {
          editorElement.removeAttribute("data-unlayer-initialized");
        }
        setIsEditorInitialized(false);
      }
    };
  }, [onEditorLoad]);

  const handleExportHtml = async () => {
    if (!editorRef.current?.editor) {
      console.error("Editor not initialized");
      return null;
    }

    return new Promise((resolve, reject) => {
      try {
        editorRef.current.editor.exportHtml((data) => {
          const { html } = data;
          if (!html) {
            reject(new Error("No HTML content generated"));
            return;
          }
          resolve(html);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (exportHtml) {
      exportHtml.current = handleExportHtml;
    }
  }, [exportHtml]);

  useEffect(() => {
    if (isEditorInitialized && window.unlayer && initialContent) {
      console.log('EmailBuilder loading content:', {
        hasContent: !!initialContent,
      });

      try {
        // Try to parse the content as JSON first in case it's a design
        const designData = JSON.parse(initialContent);
        window.unlayer.loadDesign(designData);
      } catch (e) {
        // If parsing fails, it's HTML content - create a design structure
        window.unlayer.loadDesign({
          body: {
            rows: [
              {
                cells: [1],
                columns: [
                  {
                    contents: [
                      {
                        type: "html",
                        values: {
                          html: initialContent
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        });
      }

      console.log('Content loaded into editor');
    }
  }, [isEditorInitialized, initialContent]);

  useEffect(() => {
    if (window.unlayer) {
      const editor = window.unlayer;
      
      return () => {
        if (editor) {
          editor.removeEventListener('design:updated');
        }
      };
    }
  }, []);

  // Add image upload handler
  const handleImageUpload = (file, onSuccess) => {
    console.log('Image upload requested:', file);
    // For now, return a placeholder or error
    // In production, you would upload to your storage service
    onSuccess({
      url: URL.createObjectURL(file),
      // You can also provide additional image data:
      // width: 100,
      // height: 100
    });
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ padding: "10px" }}>
        Email manager
      </Typography>
      <div style={{ height: "600px" }}>
        <EmailEditor
          ref={editorRef}
          onReady={onEditorLoad}
          options={{
            ...options,
            id: "editor",
            projectId: 256104,
            displayMode: "email",
            appearance: { theme: "modern_light" },
            tools: {
              ...options.tools,
              text: {
                enabled: true,
                properties: {
                  // Enable text editing features
                  text: {
                    value: ''
                  }
                }
              },
              image: {
                enabled: true,
                properties: {
                  src: {
                    value: ''
                  }
                }
              }
            },
            features: {
              ...options.features,
              textEditor: {
                enabled: true,
                spellChecker: true,
                tables: true,
                cleanPaste: true
              }
            }
          }}
        />
      </div>
    </Box>
  );
};

export default EmailBuilder;
