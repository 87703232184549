import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import {
  deleteAgent,
  getPlaygroundConversation,
  putAgent,
} from "../../graphql/queries";
import useStore from "../../hooks/useStore";
import DataFetcher from "../../utils/DataFetcher";
import ChatBox from "../../components/ChatBox/ChatBox";
import UseNotification from "../../utils/UseNotification";
import AssistantDetails from "../Playground/AssistantDetails";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";
import KnowlegdeBaseList from "../../components/KnowlegdeBaseList/KnowlegdeBaseList";
import { Email, Sms } from "@mui/icons-material";
import CampaignSlideOver from "./components/CampaignSlideOver";
import { playgroundStyles } from "./Playground.styles";
import CampaignSelectorModal from "./components/CampaignSelectorModal";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

const Playground = () => {
  const theme = useTheme();
  const location = useLocation();
  const { authStore } = useStore();
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [dataMessages, setDataMessages] = useState([]);
  const [agentDeleted, setAgentDeleted] = useState("");
  const [currentAgent, setCurrentAgent] = useState({});
  const [isGroqAgent, setIsGroqAgent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [updateKey, setUpdateKey] = useState(Date.now());
  const [selectedAgent, setSelectedAgent] = useState({});
  const [currentThread, setCurrentThread] = useState("");
  const [uploadedStatus, setUploadedStatus] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [customerFromClaim, setCustomer] = useState(null);
  const [openDocumentList, setOpenDocumentList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [campaignType, setCampaignType] = useState(null);
  const [showAssistantDetails, setShowAssistantDetails] = useState(false);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [selectorModalOpen, setSelectorModalOpen] = useState(false);

  const selectedPrompt = location.state?.selectedPrompt || null;
  const editingCampaign = location.state?.campaign;
  const editMode = location.state?.editMode;
  const allowedEdits = location.state?.allowedEdits;
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  } = UseNotification();

  const useConfirmationDialog = () => {
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
      setOpenConfirmation(true);
    };

    const handleCloseConfirmation = () => {
      setOpenConfirmation(false);
    };

    return {
      openConfirmation,
      handleOpenConfirmation,
      handleCloseConfirmation,
    };
  };

  const { openConfirmation, handleOpenConfirmation, handleCloseConfirmation } =
    useConfirmationDialog();

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("CustomAgentMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  useEffect(() => {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;

      Storage.configure({
        region: REGION,
        bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }
  }, []);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);

        // If we're in edit mode, trigger agent fetch immediately
        if (editMode && customer && authStore.userId) {
          console.log("Triggering initial agent fetch");
          // JP we might need to adjust this depending on your data fetcher implementation
          await dataFetcher.fetchAgents(authStore.userId, customer);
        }
      } catch (e) {
        console.error("No current user", e);
      }
    };

    checkCustomer();
  }, [editMode, authStore.userId]);

  useEffect(() => {
    if (!authStore.userId) return;

    const initialize = async () => {
      const applicantId = authStore.userId;
      const getFiles = await dataFetcher.fetchFiles(applicantId);
      setUserId(applicantId);
      setFiles(getFiles);
    };

    initialize();
  }, [authStore.userId]);

  useEffect(() => {
    localStorage.setItem("CustomAgentMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    console.log("Editing Campaign Effect:", {
      editingCampaign,
      editMode,
      allowedEdits,
    });

    if (editingCampaign && editMode) {
      const type = editingCampaign.metadata?.type?.toLowerCase() || null;
      setCampaignType(type);
      setSlideOverOpen(true);

      if (editingCampaign.metadata?.ai_agent) {
        setSelectedAgent({
          id: editingCampaign.metadata.ai_agent,
          name: editingCampaign.name || "",
          prompt: editingCampaign.instructions || "",
        });
      }
    }
  }, [editingCampaign, editMode]);

  const handlePutAgent = async (data) => {
    if (!data) return;

    const { instructions, name, selectedAgentId } = data;
    const ids = files
      .filter((file) => file.file_name !== "case_study_placeholder")
      .map((file) => file.file_name);

    const params = {
      customer_id: customerFromClaim,
      id: selectedAgentId || "",
      applicant_id: userId,
      prompt: instructions,
      provider: "openai",
      name: name,
      knowledge_base: ids,
    };

    try {
      const response = await API.graphql({
        query: putAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setIsGroqAgent(false);
      const typeMessage =
        selectedAgent && Object.keys(selectedAgent).length === 0
          ? "created"
          : "updated";

      handleOpenNotification(
        "Success",
        `Agent successfully ${typeMessage}.`,
        "success"
      );

      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred in the process, verify and try again.",
        "error"
      );
      console.error("Error saving/editing agent to database:", error);
    }
  };

  const handleDeleteAgent = async () => {
    handleCloseConfirmation();

    if (!selectedAgent?.id) {
      handleOpenNotification("Error", "No agent selected to delete.", "error");
      return;
    }

    const params = {
      customer_id: customerFromClaim,
      id: selectedAgent.id,
    };

    try {
      const response = await API.graphql({
        query: deleteAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      handleOpenNotification(
        "Success",
        "Agent successfully deleted.",
        "success"
      );
      setSelectedAgent({});
      setAgentDeleted("Yes");
      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred while deleting the agent.",
        "error"
      );
      console.error("Error deleting agent from the database:", error);
      setAgentDeleted("No");
    }
  };

  const handleSelectAgent = async (agent) => {
    console.log("handleSelectAgent called with:", agent);

    if (!agent) {
      console.log("No agent provided to handleSelectAgent");
      return;
    }

    setAgentDeleted("");
    setIsGroqAgent(false);
    setSelectedAgent(agent);
    setCurrentThread("");

    console.log("Agent selection complete:", {
      selectedAgent: agent,
      isGroqAgent: false,
      currentThread: "",
    });
  };

  const uploadFile = useCallback(
    async (event) => {
      setIsUploadingFile(true);
      const uploadedFiles = await handleFileUpload(event, userId);
      await dataFetcher.saveFilesToDatabase(
        uploadedFiles,
        userId,
        customerFromClaim
      );
      setUpdateKey(Date.now());
      setIsUploadingFile(false);
    },
    [dataFetcher, userId, customerFromClaim]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
          });
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      if (uploadedFiles.length) {
        setUploadedStatus("success");
      }
      return uploadedFiles;
    } catch (error) {
      setUploadedStatus("error");
      throw new Error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newSentMessage = { message: newMessage, type: "sent" };
    const newLoadingMessage = {
      message: "",
      type: "received",
      isLoading: true,
    };

    setCurrentAgent(
      Object.keys(selectedAgent).length === 0 ? selectedAgent : currentAgent
    );

    setMessages((prevMessages) => [
      ...prevMessages,
      newSentMessage,
      newLoadingMessage,
    ]);
    setShowSpinner(true);

    try {
      const params = {
        customer_id: customerFromClaim,
        agent_id: selectedAgent.id,
        message: newMessage,
        thread: currentThread,
        user_id: userId,
      };

      const resultConversationOpenAi = await API.graphql({
        query: getPlaygroundConversation,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (!resultConversationOpenAi.errors?.length) {
        const apiResponse =
          resultConversationOpenAi.data.getPlaygroundConversation;

        // Handle initial response
        if (apiResponse.status === "assistant chat response completed") {
          const responseBody = JSON.parse(apiResponse.body);
          setMessages((prevMessages) => {
            const filteredMessages = prevMessages.filter(
              (msg) => !msg.isLoading
            );
            return [
              ...filteredMessages,
              { message: responseBody.answer, type: "received" },
            ];
          });
          setShowSpinner(false);
          setNewMessage("");
          setCurrentThread(responseBody.thread_id);

          // Add feedback data
          const feedbackData = {
            user_id: userId,
            vote: "DOWN",
            agent_id: responseBody.agent,
            message: responseBody.answer,
            message_id: responseBody.campaign_id,
            campaign: "",
            comment: "Comment",
          };
          setDataMessages((prevMessages) => [...prevMessages, feedbackData]);
          return; // Exit if we already have the complete response
        }

        // Set up subscription for streaming responses
        let tmpMsg = "";
        let isStreaming = false;

        const assistantResponseSub = API.graphql({
          ...graphqlOperation(onChatAssistantResponse, {
            id: apiResponse.id,
          }),
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }).subscribe({
          next: ({ value }) => {
            const subApiResponse = value.data.onChatAssistantResponse;
            const body = JSON.parse(subApiResponse.body);

            if (body.error) {
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.isLoading
                    ? { ...msg, message: body.error, isLoading: false }
                    : msg
                )
              );
              setShowSpinner(false);
              return;
            }

            const openAiAnswer = body.answer;
            setShowSpinner(false);

            if (subApiResponse.status === "done") {
              if (!isStreaming) {
                setMessages((prevMessages) => {
                  const filteredMessages = prevMessages.filter(
                    (msg) => !msg.isLoading
                  );
                  return [
                    ...filteredMessages,
                    { message: openAiAnswer, type: "received" },
                  ];
                });
              }
              assistantResponseSub.unsubscribe();
              setNewMessage("");
              setCurrentThread(body.thread_id);

              const feedbackData = {
                user_id: userId,
                vote: "DOWN",
                agent_id: body.agent,
                message: openAiAnswer,
                message_id: body.message_id,
                campaign: "",
                comment: "Comment",
              };
              setDataMessages((prevMessages) => [
                ...prevMessages,
                feedbackData,
              ]);
            } else {
              isStreaming = true;
              tmpMsg += openAiAnswer;
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.isLoading
                    ? { ...msg, message: tmpMsg, isLoading: false }
                    : msg
                )
              );
            }
          },
          error: (error) => {
            console.error("Subscription error:", error);
            setShowSpinner(false);
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.isLoading
                  ? {
                      ...msg,
                      message: "Error receiving message. Please try again.",
                      isLoading: false,
                    }
                  : msg
              )
            );
          },
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setShowSpinner(false);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.isLoading
            ? {
                ...msg,
                message: "Error sending message. Please try again.",
                isLoading: false,
              }
            : msg
        )
      );
    }
  };

  const handleClearMessages = () => {
    setMessages([]);
    setCurrentThread("");
    localStorage.removeItem("AdminChatMessages");
  };

  const handleConfirmationDialog = () => {
    handleOpenConfirmation();
  };

  const handleRejectConfirmation = () => {
    setAgentDeleted("No");
    handleCloseConfirmation();
  };

  const toggleFileList = useCallback(() => {
    setOpenDocumentList((prev) => !prev);
  }, []);

  const handleClick = () => {
    if (!hasSelectedAgent) {
      handleOpenNotification(
        "Error",
        "Please select or create an agent first",
        "error"
      );
      return;
    }
    setSelectorModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    if (editMode) {
      handleOpenNotification(
        "Error",
        "Cannot start new campaign while editing",
        "error"
      );
      return;
    }

    if (!hasSelectedAgent) {
      handleOpenNotification(
        "Error",
        "Please select or create an agent first",
        "error"
      );
      return;
    }

    setCampaignType(option);
    setSlideOverOpen(true);
  };

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setCampaignType(null);
  };

  const hasSelectedAgent =
    selectedAgent && Object.keys(selectedAgent || {}).length > 0;
  console.log("Render state:", {
    selectedAgent,
    hasSelectedAgent,
    editMode,
    allowedEdits,
    campaignType,
    isEmailCampaign: campaignType === "email",
  });
  const isEmailCampaign = campaignType === "email";

  const isFieldEditable = (fieldName) => {
    console.log("Checking field editability:", {
      fieldName,
      editMode,
      allowedEdits,
      result:
        !editMode ||
        allowedEdits === "all" ||
        allowedEdits?.includes(fieldName),
    });

    if (!editMode) return true;
    if (allowedEdits === "all") return true;
    return allowedEdits?.includes(fieldName);
  };

  // Prepare campaign data with the correct structure
  const prepareEditCampaignData = () => {
    if (!editMode || !editingCampaign) return null;

    // Parse sender info from EMAIL channel
    let senderName = "";
    let senderEmail = "";

    if (editingCampaign.channels?.EMAIL) {
      const emailMatch =
        editingCampaign.channels.EMAIL.match(/(.*?)\s*<(.+?)>/);
      if (emailMatch) {
        senderName = emailMatch[1].trim();
        senderEmail = emailMatch[2].trim();
      }
    }

    // Get first message content for retries
    const firstMessageContent =
      editingCampaign.first_message || editingCampaign.context || "";

    // Initialize retries with first message as template AND preserve existing retries
    let initialRetries = editingCampaign.metadata?.retries || [];

    // Only add first message as first retry if there are no retries yet
    if (firstMessageContent && initialRetries.length === 0) {
      initialRetries = [
        {
          days: "0",
          template: {
            name: "Current Template",
            body: firstMessageContent,
            message: firstMessageContent,
          },
        },
      ];
    }

    // Ensure all retries have proper template structure
    initialRetries = initialRetries.map((retry, index) => ({
      days: retry.days || String(index),
      template: {
        name: retry.template?.name || `Template ${index}`,
        body: retry.template?.body || retry.message || "",
        message: retry.template?.message || retry.message || "",
      },
    }));

    return {
      agentId: selectedAgent.id,
      campaignId: editingCampaign.id,
      status: editingCampaign.status,
      emailSubject: editingCampaign.metadata?.subject,
      context: editingCampaign.context,
      emailHtml: editingCampaign.metadata?.email_content,
      selectedContacts: editingCampaign.metadata?.contacts || [],
      senderEmail: senderEmail || editingCampaign.metadata?.sender_email,
      senderName: senderName || editingCampaign.metadata?.sender_name,
      contactsFile: editingCampaign.metadata?.contacts_file,
      mappedFields: editingCampaign.metadata?.mapped_fields,
      selectionMode: editingCampaign.metadata?.selection_mode,
      retries: initialRetries,
      variables: editingCampaign.metadata?.variables || [],
      first_message: firstMessageContent,
    };
  };

  const getMainContentStyles = () => ({
    ...playgroundStyles.mainContent,
    width: editMode
      ? slideOverOpen
        ? "100%"
        : "calc(100% - 20%)"
      : slideOverOpen
      ? `calc(100% - ${isEmailCampaign ? "50%" : "30%"})`
      : "100%",
    marginRight: editMode
      ? 0
      : slideOverOpen
      ? isEmailCampaign
        ? "50%"
        : "30%"
      : 0,
    transition: "all 0.3s ease-in-out",
    display: "flex",
    flexDirection: "column",
    height: "89vh",
  });

  return (
    <Box
      sx={{
        ...playgroundStyles.container,
        flexDirection: isMobileOrTablet ? "column" : "row",
        height: "89vh",
        overflow: "hidden",
      }}
    >
      {openDocumentList && (
        <KnowlegdeBaseList
          open={openDocumentList}
          onClose={toggleFileList}
          files={files}
        />
      )}
      <Box
        sx={{
          ...playgroundStyles.assistantDetails,
          display: editMode ? "flex" : "block",
          position: editMode ? "fixed" : "relative",
          left: editMode ? 0 : "auto",
          top: editMode ? 0 : "auto",
          height: editMode ? "100vh" : "89vh",
          width: editMode ? "400px" : "20%",
          zIndex: editMode ? 1300 : "auto",
          transform: editMode
            ? showAssistantDetails
              ? "translateX(0)"
              : "translateX(-400px)"
            : "none",
          transition: "transform 0.3s ease-in-out",
          backgroundColor: "#fff",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
          boxShadow: editMode ? "2px 0 8px rgba(0,0,0,0.1)" : "none",
          overflow: "auto",
        }}
      >
        <AssistantDetails
          selectedPrompt={selectedPrompt}
          applicantId={userId}
          agentDeleted={agentDeleted}
          onUploadFile={uploadFile}
          onCreateOrUpdate={handlePutAgent}
          onDelete={handleConfirmationDialog}
          onSelectAgent={handleSelectAgent}
          toggleFileList={toggleFileList}
          editMode={editMode}
          isFieldEditable={isFieldEditable}
          campaignData={editingCampaign}
        />
      </Box>
      <Box
        sx={getMainContentStyles()}
        opacity={hasSelectedAgent ? 1 : 0.5}
        pointerEvents={hasSelectedAgent ? "auto" : "none"}
        filter={hasSelectedAgent ? "none" : "grayscale(100%)"}
      >
        <Box
          sx={{
            ...playgroundStyles.chatWindow,
            display: editMode ? "flex" : "flex",
            position: editMode ? "fixed" : "relative",
            right: editMode ? 0 : "auto",
            top: editMode ? 0 : "auto",
            height: "100vh",
            width: editMode ? "400px" : "auto",
            zIndex: editMode ? 1300 : "auto",
            transform: editMode
              ? showChatWindow
                ? "translateX(0)"
                : "translateX(400px)"
              : "none",
            transition: "transform 0.3s ease-in-out",
            backgroundColor: "#fff",
            borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
            boxShadow: editMode ? "-2px 0 8px rgba(0,0,0,0.1)" : "none",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <ChatWindow
            messages={messages}
            isDisabled={!hasSelectedAgent}
            dataMessages={dataMessages}
          />
          <Box
            sx={{
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: "8px",
            }}
          >
            {!editMode && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    size="small"
                    disabled={!hasSelectedAgent || editMode}
                    sx={{
                      ...playgroundStyles.campaignButton,
                      opacity: !hasSelectedAgent || editMode ? 0.6 : 1,
                      cursor:
                        !hasSelectedAgent || editMode
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    Start Campaign
                  </Button>
                </Box>
                <CampaignSelectorModal
                  open={selectorModalOpen}
                  onClose={() => setSelectorModalOpen(false)}
                  onSelect={handleOptionSelect}
                  disabled={!hasSelectedAgent || editMode}
                />
              </>
            )}
            <ChatBox
              files={files}
              isAdmin={true}
              isGroqAgent={isGroqAgent}
              showSpinner={showSpinner}
              currentMessage={newMessage}
              uploadedStatus={uploadedStatus}
              isUploadingFile={isUploadingFile}
              onUploadFile={uploadFile}
              onUpdateMessages={(message) => setNewMessage(message)}
              onSendMessage={handleSendMessage}
              clearMessages={handleClearMessages}
              isDisabled={!hasSelectedAgent}
            />
          </Box>
        </Box>
        {!hasSelectedAgent && (
          <Box sx={playgroundStyles.noAgentMessage}>
            No agent has been selected or created
          </Box>
        )}
      </Box>
      <CampaignSlideOver
        open={slideOverOpen}
        type={campaignType}
        agentId={selectedAgent.id}
        userId={userId} //This is really an email not dynamo id
        customerFromClaim={customerFromClaim}
        onClose={handleCloseSlideOver}
        editMode={editMode}
        campaignData={prepareEditCampaignData()}
      />
      <NotificationDialog
        open={openNotification}
        onClose={handleCloseNotification}
        title={notificationContent.title}
        message={notificationContent.message}
        type={notificationContent.type}
        sx={playgroundStyles.notificationDialog}
      />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleDeleteAgent}
        onReject={handleRejectConfirmation}
        message="Are you sure you want to delete this agent? This action cannot be undone."
        sx={playgroundStyles.confirmationDialog}
      />
      {editMode && slideOverOpen && (
        <>
          {/* Assistant Details Toggle Button */}
          <Button
            onClick={() => setShowAssistantDetails(!showAssistantDetails)}
            sx={{
              position: "fixed",
              left: showAssistantDetails ? "400px" : 0,
              top: "50%",
              transform: "translateY(-50%)",
              minWidth: "24px",
              height: "100px",
              backgroundColor: "#015d86",
              borderRadius: showAssistantDetails
                ? "0 4px 4px 0"
                : "0 8px 8px 0",
              "&:hover": {
                backgroundColor: "#014a6d",
                left: showAssistantDetails ? "400px" : "5px",
              },
              transition: "all 0.3s ease-in-out",
              zIndex: 1400,
              padding: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
                transform: showAssistantDetails ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              {/* Custom arrow icon */}
              <Box sx={{ fontSize: "20px" }}>›</Box>
            </Box>
          </Button>

          {/* Chat Window Toggle Button */}
          <Button
            onClick={() => setShowChatWindow(!showChatWindow)}
            sx={{
              position: "fixed",
              right: showChatWindow ? "400px" : 0,
              top: "50%",
              transform: "translateY(-50%)",
              minWidth: "24px",
              height: "100px",
              backgroundColor: "#015d86",
              borderRadius: showChatWindow ? "4px 0 0 4px" : "8px 0 0 8px",
              "&:hover": {
                backgroundColor: "#014a6d",
                right: showChatWindow ? "400px" : "5px",
              },
              transition: "all 0.3s ease-in-out",
              zIndex: 1400,
              padding: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "white",
                transform: showChatWindow ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              {/* Custom arrow icon */}
              <Box sx={{ fontSize: "20px" }}>‹</Box>
            </Box>
          </Button>
        </>
      )}
    </Box>
  );
};

export default Playground;
