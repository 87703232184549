import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import UrlListManager from "../../components/UrlListManager/UrlListManager";
import DomainListManager from "../../components/DomainListManager/DomainListManager";
import PhoneListManager from "../../components/PhoneListManager/PhoneListManager";

const CustomerForm = ({
  fields,
  initialData = {},
  title,
  submitButtonText,
  onSubmit,
  onClose,
}) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
    // Populate formData with channel data if available
    if (initialData.channels) {
      setFormData((prevData) => ({
        ...prevData,
        email_domains: initialData.channels.EMAIL || [],
        sms_numbers: initialData.channels.SMS || [],
        voice_numbers: initialData.channels.VOICE || [],
      }));
    }
  }, [initialData]);

  const validateField = useCallback(
    (name, value) => {
      const field = fields.find((field) => field.name === name);
      if (!field?.validate) return "";
      if (
        field.type === "UrlListManager" ||
        field.type === "DomainListManager" ||
        field.type === "PhoneListManager"
      ) {
        return field.validate(value || []);
      }
      return field.validate(value || "");
    },
    [fields]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validateField(name, value),
      }));
    },
    [validateField]
  );

  const handleListChange = (name, updatedList) => {
    console.log(`Updating ${name} with:`, updatedList);
    if (name === "email_domains") {
      console.log("Email domains updated:", updatedList);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedList,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, updatedList),
    }));
  };

  const validateForm = useCallback(() => {
    let isValid = true;
    const newErrors = {};

    fields.forEach(({ name }) => {
      const error = validateField(name, formData[name]);
      newErrors[name] = error;
      if (error) {
        console.log(`Validation error for ${name}:`, error);
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  }, [fields, formData, validateField]);

  const handleSubmit = useCallback(() => {
    console.log("Form data before submission:", formData);
    if (validateForm()) {
      try {
        // Ensure email_domains is always an array
        const emailDomains = Array.isArray(formData.email_domains)
          ? formData.email_domains
          : formData.email_domains
          ? [formData.email_domains]
          : [];

        const submissionData = {
          ...formData,
          domains: formData.domains || [],
          email_domains: emailDomains,
          sms_numbers: formData.sms_numbers || [],
          voice_numbers: formData.voice_numbers || [],
        };
        console.log("Final submission data:", submissionData);
        onSubmit(submissionData);
      } catch (error) {
        console.error("Error in form submission:", error);
      }
    } else {
      console.log("Form validation failed", errors);
    }
  }, [formData, validateForm, onSubmit, errors]);

  return (
    <Box
      p={3}
      sx={{
        width: 350,
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column">
        {fields.map(({ name, label, type }) => (
          <Box mb={2} key={name}>
            <Typography variant="subtitle2" fontWeight="bold">
              {label}
            </Typography>
            {type === "UrlListManager" && (
              <UrlListManager
                urls={formData[name] || []}
                onChange={(urls) => handleListChange(name, urls)}
              />
            )}
            {type === "DomainListManager" && (
              <DomainListManager
                domains={formData[name] || []}
                onDomainListChange={(domains) =>
                  handleListChange(name, domains)
                }
              />
            )}
            {type === "PhoneListManager" && (
              <PhoneListManager
                phones={formData[name] || []}
                onPhoneListChange={(phones) => handleListChange(name, phones)}
              />
            )}
            {(type === "text" || type === "email") && (
              <TextField
                fullWidth
                variant="outlined"
                name={name}
                type={type}
                value={formData[name] || ""}
                onChange={handleInputChange}
                error={!!errors[name]}
                helperText={errors[name]}
                sx={{
                  "& .MuiInputBase-root": { height: "36px" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "black" },
                    "&:hover fieldset": { borderColor: "black" },
                    "&.Mui-focused fieldset": { borderColor: "black" },
                  },
                }}
              />
            )}
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              height: "32px",
              backgroundColor: "#0D92B7",
              "&:hover": { backgroundColor: "#015d86" },
            }}
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerForm;
