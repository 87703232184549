import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Paper,
  Badge,
  CircularProgress,
  Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowLeft,
  faComment,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import ConversationList from "./ConversationList";
import PropTypes from "prop-types";

/**
 * Displays a conversation thread between an agent and a contact
 * Shows messages in chronological order with proper styling for agent/contact messages
 * @param {Object} props
 * @param {Object} props.campaign - Campaign details
 * @param {string} props.account - Account identifier
 * @param {Function} props.onClose - Handler for closing the conversation viewer
 */
const ConversationViewer = ({ campaign, account, onClose }) => {
  const [selectedThread, setSelectedThread] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Add debug logging
  useEffect(() => {
    if (selectedThread) {
      console.log("Selected thread updated:", {
        thread: selectedThread,
        messageCount: selectedThread.messages?.length,
        messages: selectedThread.messages,
      });
    }
  }, [selectedThread]);

  // Add console logs to check the types
  console.log("ConversationViewer - campaign:", {
    value: campaign?.id,
    type: typeof campaign?.id,
  });
  console.log("ConversationViewer - account:", {
    value: account,
    type: typeof account,
  });

  const handleThreadClick = (thread) => {
    if (!thread) {
      console.error("Attempted to select null thread");
      return;
    }

    setSelectedThread({
      ...thread,
      name: thread.email,
      messages:
        thread.messages?.map((msg) => ({
          ...msg,
          type: msg.type,
          content: msg.content || msg.message,
          timestampStr: msg.timestampStr || formatTimestamp(msg.timestamp),
        })) || [],
    });
  };

  const handleBackClick = () => {
    setSelectedThread(null);
  };

  const StatusBadge = ({ status }) => (
    <Box
      component="span"
      sx={{
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: status === "online" ? "#22c55e" : "#94a3b8",
        display: "inline-block",
        marginLeft: 1,
      }}
    />
  );

  // Add formatTimestamp helper function
  const formatTimestamp = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleString();
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        right: 0,
        top: 0,
        width: "50%",
        height: "100vh",
        backgroundColor: "#ffffff",
        zIndex: 1200,
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px 0 0 16px",
        overflow: "hidden",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2.5,
          borderBottom: "1px solid #e2e8f0",
          backgroundColor: "#ffffff",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {selectedThread && (
            <IconButton
              onClick={handleBackClick}
              size="small"
              sx={{
                backgroundColor: "#f1f5f9",
                "&:hover": { backgroundColor: "#e2e8f0" },
              }}
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ color: "#64748b" }}
              />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ fontWeight: 600, color: "#1e293b" }}>
            {selectedThread
              ? selectedThread.name
              : campaign?.metadata?.name || "Campaign Details"}
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            backgroundColor: "#f1f5f9",
            "&:hover": { backgroundColor: "#fee2e2" },
          }}
        >
          <FontAwesomeIcon icon={faTimes} style={{ color: "#ef4444" }} />
        </IconButton>
      </Box>

      {/* Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          backgroundColor: selectedThread ? "#f8fafc" : "#ffffff",
          p: 2,
        }}
      >
        {!selectedThread ? (
          <ConversationList
            campaign={campaign?.id}
            account={account}
            onSelect={(conversation) =>
              setSelectedThread({
                ...conversation,
                name: conversation.email,
              })
            }
          />
        ) : (
          // Conversation View
          <Box>
            <Box
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
                gap: 2,
                p: 2,
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor:
                      selectedThread.status === "online"
                        ? "#22c55e"
                        : "#94a3b8",
                    border: "2px solid #ffffff",
                  },
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "#0D92B7",
                    width: 48,
                    height: 48,
                    fontSize: "1.2rem",
                  }}
                >
                  {selectedThread.name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </Avatar>
              </Badge>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#1e293b" }}
                >
                  {selectedThread.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {selectedThread.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ my: 2, px: 2 }}>
              {selectedThread?.messages?.map((message, index) => {
                console.log("Rendering message:", {
                  content: message.content,
                  type: message.type,
                  direction: message.direction,
                  sender: message.sender,
                  receiver: message.receiver,
                });
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        message.type === "user" ? "flex-end" : "flex-start",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor:
                          message.type === "user" ? "#0ea5e9" : "#f1f5f9",
                        p: 2.5,
                        borderRadius:
                          message.type === "user"
                            ? "20px 20px 4px 20px"
                            : "20px 20px 20px 4px",
                        maxWidth: "70%",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                        position: "relative",
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          position: "absolute",
                          top: -20,
                          left: message.type === "user" ? "auto" : 0,
                          right: message.type === "user" ? 0 : "auto",
                          color: "#64748b",
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        }}
                      >
                        {message.role === "CONTACT" ? "Contact" : "Agent"}
                      </Typography>
                      <Typography
                        sx={{
                          color: message.type === "user" ? "white" : "#1e293b",
                          fontSize: "0.95rem",
                          lineHeight: 1.5,
                        }}
                      >
                        {message.content}
                      </Typography>
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 1,
                        color: "#64748b",
                        fontSize: "0.75rem",
                      }}
                    >
                      {message.timestampStr}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

ConversationViewer.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  account: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default ConversationViewer;
