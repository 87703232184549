import React, { useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const UserForm = ({
  fields,
  initialData = {},
  title,
  submitButtonText,
  onSubmit,
  onClose,
}) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const validateField = useCallback(
    (name, value) => {
      const field = fields.find((field) => field.name === name);
      return field?.validate ? field.validate(value) : "";
    },
    [fields]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validateField(name, value),
      }));
    },
    [validateField]
  );

  const validateForm = useCallback(() => {
    let isValid = true;
    const newErrors = {};

    fields.forEach(({ name }) => {
      const error = validateField(name, formData[name]);
      newErrors[name] = error;
      if (error) isValid = false;
    });

    setErrors(newErrors);
    return isValid;
  }, [fields, formData, validateField]);

  const handleSubmit = useCallback(() => {
    if (validateForm()) {
      onSubmit(formData);
      onClose();
    }
  }, [formData, validateForm, onSubmit, onClose]);

  return (
    <Box
      p={3}
      sx={{
        width: 350,
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
    >
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: "bold" }}>
          {title}
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" sx={{ fontWeight: "bold", ml: "5px" }}>
          Avatar
        </Typography>
        <Box
          mb={2}
          sx={{
            gap: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Avatar sx={{ width: 56, height: 56, mb: 1 }} />
          <Button
            variant="contained"
            sx={{
              ml: 1,
              mb: 1,
              height: "32px",
              backgroundColor: "#0D92B7",
              "&:hover": { backgroundColor: "#015d86" },
            }}
          >
            <Typography sx={{ fontSize: "12px" }}>Upload</Typography>
          </Button>
        </Box>
        {fields.map(({ name, label, type }) => (
          <Box mb={2} key={name}>
            <Typography variant="subtitle2" fontWeight="bold">
              {label}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              name={name}
              type={type}
              value={formData[name] || ""}
              onChange={handleInputChange}
              error={!!errors[name]}
              helperText={errors[name]}
              sx={{
                "& .MuiInputBase-root": { height: "36px" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
            />
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              height: "32px",
              backgroundColor: "#0D92B7",
              "&:hover": { backgroundColor: "#015d86" },
            }}
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserForm;
